import { BASE_URL } from './baseUrl_local'
import { QGIS_URL } from './baseUrl_local'

export const baseUrl = BASE_URL // сервер куда стучимся
export const qgisUrl = QGIS_URL // qgis сервер

export const urlTileQgs = qgisUrl + '/ogc/{passportId}/'
export const urlTileQgsWithParams = urlTileQgs + '?service={service}&request={request}&layers={layers}&styles=&format={format}&transparent={transparent}&version={version}&width={width}&height={height}&srs={srs}&bbox={bbox}'
export const urlTileGoogle = 'https://mt1.google.com/vt/lyrs=s'
export const urlTileGoogleWithParams = urlTileGoogle + '&x={x}&y={y}&z={z}?service={service}&request={request}&layers=&styles=&format={format}&transparent={transparent}&version={version}&width={width}&height={height}&srs={srs}&bbox={bbox}'
export const urlTileESRI = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
export const maxZoom = 24 //общий максимальный зум для всех моментов. И для скачивания и для отображения

export const baseTimeout = 60000

export const maxFileSize = 10 * 1024 * 1024
export const maxImageSize = 1024 * 1024 * 10
export const maxLayerSize = 1000 * 1024 * 1024

export const defaultImageUrl = 'https://via.placeholder.com/300?text=нет иллюстрации'

export const phoneMask = '8 ### ### ## ##'

export const roles = [
  { name: 'admin', label: 'Администратор' },
  { name: 'manager', label: 'Менеджер' },
  { name: 'auditor', label: 'Аудитор' },
  { name: 'unit', label: 'Юнит' },
  { name: 'observer', label: 'Наблюдатель' },
  { name: 'demoUnit', label: 'Демо юнит' }
]

export const geometryTypes = [
  { name: 'Point', label: 'Точка' },
  { name: 'LineString', label: 'Линия' },
  { name: 'Polygon', label: 'Полигон' },
  { name: 'MultiPolygon', label: 'Мультиполигон' }
]

export const confirmTypes = [
  { value: 1, label: 'Да' },
  { value: 0, label: 'Нет' }
]

export const scenarioOrigin = 'origin'
export const scenarioCommit = 'commit'
export const scenarioDiff = 'diff'

export const urlTypesLoad = {
  start: 'process/start',
  status: 'process/status',
  download: 'filemanager/file/download'
}

export const typesFileReports = {
  geo: {
    passport: 'geoJsonFilePassport',
    object: 'geoJsonFilePassportObject'
  },
  xls: {
    passport: 'excelPassport',
    object: 'excelPassportObject'
  },
  svodka: 'passportSvodka',
  reestr: 'reestrOzelennenikhTerritoriy' // note:
}

export const filterBaseAttrs = ['longitude', 'latitude', 'id', 'element_number', 'state']
