import { recLookup } from '@/components/utils/common'
import appeal from '@/messages/ru/appeal'
import base from './ru/base'
import login from './ru/login'
import constructor from '@/messages/ru/constructor'
import organization from './ru/organization'
import user from './ru/user'
import subscription from './ru/subscription'
import message from './ru/message'
import passport from './ru/passport'
import handbook from '@/messages/ru/handbook'
import map from '@/messages/ru/map'
import report from '@/messages/ru/report'
import region from '@/messages/ru/region'
import verification from '@/messages/ru/verification'
import dashboard from '@/messages/ru/dashboard'
import publicText from './ru/public'

let ru = {
  appeal,
  base,
  login,
  constructor,
  dashboard,
  organization,
  user,
  subscription,
  message,
  passport,
  handbook,
  map,
  report,
  region,
  verification,
  publicText
}

export function translate (alias) {
  return recLookup(ru, alias)
}
