<template>
  <div class="mb-3">
    <v-icon
      title="Подтвержден"
      v-if="value"
      :color="value ? 'success' : 'warning'"
      :disabled="disabled"
    >
      mdi-check
    </v-icon>
  </div>
</template>

<script>
import { confirmTypes } from '@/config/common'

export default {
  name: 'BuilderConfirm',
  props: {
    label: String,
    value: [Boolean, Number],
    prependInnerIcon: String,
    disabled: Boolean
  },
  data () {
    return {
      statusList: confirmTypes
    }
  },
  computed: {
    statusLabel () {
      return this.statusList.find(item => item.value === this.value * 1).label
    }
  }
}
</script>

<style scoped>
  .alertBuilderConfirm {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
