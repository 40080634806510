<template>
  <div>
    <p
      v-if="showLabel"
      class="mb-2"
      :class="$route.name !== 'map' ? '' : 'caption mb-0'"
    >{{ label }}</p>
    <v-autocomplete
      v-if="items"
      :items="items"
      v-model="modelChips"
      @input="model = $event"
      :item-value="itemValue"
      :item-text="itemText"
      :hide-no-data="hideNoData"
      :disabled="disabled"
      :append-icon="appendIcon"
      :search-input.sync="search"
      dense
      outlined
      flat
      :solo-inverted="soloInverted"
      :no-filter="isNoFilter"
      :multiple="isMultiple"
      @focus="items = dataItems"
      :menu-props="{ maxHeight: '200px' }"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :hide-details="!errorMessages || !errorMessages.length"
      :class="classText"
      :placeholder="'Поиск ' + label.toLowerCase()"
      return-object
    >
    </v-autocomplete>
  </div>
</template>

<script>

import computedCurrentFilter from '@/components/mixins/computedCurrentFilter'
import builders from '@/components/views/account/passport/detail/formBuilder/builders'

export default {
  name: 'BaseSearchSelect',
  mixins: [computedCurrentFilter, builders],
  props: {
    appendIcon: String,
    prependIcon: String,
    value: [Array, Number, String, Object],
    propData: Array,
    prependInnerIcon: String,
    itemText: {
      type: String,
      default: 'value'
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    emptyValue: Boolean,
    outlined: Boolean,
    label: String,
    name: String,
    color: String,
    hideDetails: Boolean,
    errorMessages: Array,
    categoryId: Number,
    disabled: Boolean,
    isMultiple: { type: Boolean, default: false },
    params: Object,
    minNumberOfWords: { type: Number, default: 1 },
    hideNoData: {
      type: Boolean,
      default: true
    },
    isTransformAppendIcon: { // крутить ли кнопку при открытии селекта
      type: Boolean,
      default: false
    },
    soloInverted: Boolean,
    eavEntityId: Number,
    showLabel: { // для отображения label над инпутом
      type: Boolean,
      default: true
    },
    classForm: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      items: [],
      dataItems: [],
      loading: false,
      search: null,
      model: [],
      modelChips: [],
      autocompleteString: ''
    }
  },
  watch: {
    search: {
      async handler () {
        const clearSearch = this.search
        if (clearSearch === '' || clearSearch === null) {
          this.autocompleteString = ''
        } else if (!this.loading && clearSearch.length >= this.charsToSearch && (!this.model || this.search !== this.model[this.itemText])) {
          this.autocompleteString = clearSearch
        } else {
          this.autocompleteString = ''
        }
        return true
      }
    },
    model: {
      handler: function () {
        if (this.model) {
          let data
          if (Array.isArray(this.model)) {
            data = this.model?.map((item) => {
              if (item?.id) {
                return item.id
              } else if (item) {
                return item // item.id обычно нет, если item - просто число, а не объект
              }
            })
          } else {
            data = this.model[this.itemValue] || this.model // либо объект, либо просто число
          }
          this.$emit('input', data)
        }
      },
      deep: true
    }
  },
  methods: {
    // todo: отрефакторить метод, вынести пустые элементы в стор (withEmpty)
    getItems () {
      if (this.propData) {
        this.$set(this, 'dataItems', this.propData)
        const emptyObj = {
          [this.itemText]: 'Не указан',
          [this.itemValue]: null
        }
        this.$set(this, 'dataItems', [emptyObj, ...this.dataItems])
        if (this.emptyValue) {
          const allObj = {
            [this.itemText]: 'Все',
            [this.itemValue]: undefined
          }
          this.$set(this, 'dataItems', [allObj, ...this.dataItems])
        }
        this.$set(this, 'items', this.dataItems)
        this.modelChips = 'Все'
        if (this.value) {
          this.modelChips = this.propData.find(item => item.value === this.value)
        }
        this.model = null
      } else {
        const response = this.$store.getters['handbook/getListItemById'](this.categoryId)
        this.dataItems = response?.values
        if (this.emptyValue && this.dataItems) {
          this.dataItems.unshift(
            {
              value: 'Все',
              id: undefined
            },
            {
              value: 'Не указан',
              id: null
            }
          )
        }
        if (this.value) {
          this.modelChips = this.value
        }
        if (!this.value && this.emptyValue) {
          this.modelChips = this.value
          this.model = this.value
        }
        this.items = this.dataItems
      }
      return true
    },
    checkQuery () {
      //функция для значений в поле из урла
      for (let key in this.$route.query.filter) {
        const itemData = this.$route.query.filter[this.name]
        if (key === this.name && itemData !== '' && itemData !== null) {
          this.fillModelChips(itemData)
        }
      }
    },
    fillModelChips (itemData) { //заполнение model, modelChips
      if (this.isMultiple) {
        itemData?.map((element) => {
          if (!!element) {
            this.model.push(element)
            this.modelChips.push(this.dataItems?.find((item) => Number(item.id) === Number(element)) || null)
          }
        })
      } else {
        this.model = itemData
        this.modelChips = this.dataItems?.find((item) => Number(item.id) === Number(itemData)) ||
          this.dataItems?.find((item) => Number(item.value) === Number(itemData))
      }
    }
  },
  computed: {
    isNoFilter () {
      // не фильтровать если количество слов меньше minNumberOfWords
      return this.search?.split(' ').length < this.minNumberOfWords
    }
  },
  mounted () {
    this.getItems()
    Object.keys(this.$route?.query).length !== 0 && this.checkQuery()
    if (this.currentItemFilter && this.$route.name === 'map' && !this.model?.length) {
      this.fillModelChips(this.currentItemFilter)
    }
  }
}
</script>

<style scoped>
  .noTransformAppendIcon /deep/ .v-input__control .v-select__slot .v-input__append-inner .v-icon {
    transform: none !important;
  }
  /deep/ .v-text-field input {
    padding: 3px 0 2px;
  }
</style>
