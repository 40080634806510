<template>
  <div class="mb-3 mt-3">
    {{ label }}
    <div class="d-flex justify-lg-space-between mt-2 mb-2">
      <v-text-field
        id="range-filed-1"
        :value="range[0]"
        class="mt-0 pt-0 mr-5"
        hide-details="auto"
        single-line
        hide-spin-buttons
        outlined
        dense
        :rules="rules"
        type="number"
        label="От"
        style="width: 60px"
        :disabled="disabled"
        @change="handleChange($event, 0)"
      ></v-text-field>
      <v-text-field
        id="range-filed-2"
        :value="range[1]"
        class="mt-0 pt-0"
        hide-details="auto"
        single-line
        label="До"
        outlined
        :rules="rules"
        dense
        type="number"
        :disabled="disabled"
        style="width: 60px"
        @change="handleChange($event, 1)"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
import computedCurrentFilter from '@/components/mixins/computedCurrentFilter'
import _ from 'lodash'

export default {
  name: 'BaseRange',
  mixins: [computedCurrentFilter],
  props: {
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    label: String,
    name: String,
    disabled: Boolean,
    eavEntityId: Number,
    value: Array
  },
  data () {
    return {
      range: [null, null],
      rules: [
        value => {
          let result = true
          if (!isNaN(this.min)) {
            if (value < this.min) {
              result = 'Не должно быть меньше ' + this.min
            }
          }
          return result
        },
        value => {
          let result = true
          if (!isNaN(this.max)) {
            if (value > this.max) {
              result = 'Не должно быть больше ' + this.max
            }
          }
          return result
        }
      ],
      model: ''
    }
  },
  watch: {
    range: {
      handler () {
        return this.$emit('input', this.range)
      },
      deep: true
    },
    value: {
      handler () {
        if (this.value?.length === 2 && !_.isEqual(this.value, this.range)) {
          this.range = this.value
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    input: {
      get () {
        return this.range
      }
    }
  },
  methods: {
    checkFieldRoute () {
      if (this.$route.name === 'map' && this.currentItemFilter) {
        this.range = this.currentItemFilter
      } else if (Object.keys(this.$route?.query).length !== 0 && !!this.$route?.query?.filter && !!this.$route.query?.filter[this.name]) {
        this.range.splice(0, 1, Number(this.$route.query.filter[this.name][0]) || null)
        this.range.splice(1, 1, Number(this.$route.query.filter[this.name][1]) || null)
      }
    },
    handleChange (event, index) {
      this.range.splice(index, 1, Number(event))
    }
  },
  mounted () {
    this.checkFieldRoute()
  }
}
</script>

<style scoped>
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
</style>