import Vue from 'vue'
import {
  LMap,
  LControlZoom,
  LControlScale,
  LTileLayer
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

import 'leaflet.control.layers.tree'
import 'leaflet.control.layers.tree/L.Control.Layers.Tree.css'

import 'leaflet.polylinemeasure'
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css'

import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-control-zoom', LControlZoom)
Vue.component('l-control-scale', LControlScale)

