<template>
  <div>
    <p
      v-if="$route.meta.layout !== 'MobileLayout'"
      :class="$route.name !== 'map' ? '' : 'caption mb-0'"
    >{{ label }}</p>
    <v-text-field
      :label="$route.name !== 'map' ? label : null"
      @input="model = $event"
      v-model="model"
      dense
      outlined
      flat
      :solo-inverted="soloInverted"
      :hide-details="hideDetails"
      :append-icon="appendIcon"
      :prepend-inner-icon="prependInnerIcon"
      :class="$route.name !== 'map' ? 'mt-2' : ''"
      :disabled="disabled"
    >
    </v-text-field>
  </div>
</template>

<script>

import computedCurrentFilter from '@/components/mixins/computedCurrentFilter'

export default {
  name: 'BuilderText',
  mixins: [computedCurrentFilter],
  props: {
    value: String,
    label: String,
    prependInnerIcon: String,
    appendIcon: String,
    name: String,
    hideDetails: Boolean,
    soloInverted: Boolean,
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    dataType: {
      type: String,
      default: 'string'
    },
    disabled: Boolean,
    eavEntityId: Number
  },
  data () {
    return {
      model: '',
      rules: [
        value => {
          let result = true
          if (value?.length) {
            const isValidTypeValue = typeof value === this.dataType
            if (this.isNumber(value) ? false : isValidTypeValue) {
              result = true
            } else {
              result = 'Тип данных должен быть: ' + this.$t('base.' + this.dataType)
            }
          }
          return result
        },
        value => {
          let result = true
          if (value?.length) {
            if (value?.length <= this.min) {
              result = 'Длина не должна быть меньше ' + this.min
            } else if (value?.length >= this.max) {
              result = 'Длина не должна быть больше ' + this.max
            }
          }
          return result
        }
      ]
    }
  },
  watch: {
    model: {
      handler: function () {
        this.$emit('input', this.model)
      },
      deep: true
    }
  },
  methods: {
    isNumber (value) {
      return this.dataType === 'number' ? !!Number(value) : !!Number(value)
    },
    checkFieldRoute () {
      if (this.$route.name === 'map' && this.currentItemFilter) {
        this.model = this.currentItemFilter
      } else if (!!this.$route.query?.filter && Object.keys(this.$route?.query).length !== 0) {
        this.model = this.$route.query.filter[this.name]
      } else if (this.value) {
        this.model = this.value
      }
    }
  },
  mounted () {
    this.checkFieldRoute()
  }
}

</script>