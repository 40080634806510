import store from '@/store'
import router from '@/router'
import { scenarioCommit, scenarioOrigin } from '@/config/common'
import _ from 'lodash'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import moment from 'moment'

// Полное удаление реактивности
export function cleanClone (obj) {
  return obj ? _.cloneDeep(obj) : obj
}

// Проверяем равенство массивов и объектов
export function deepEqual (entity1, entity2) {
  return _.isEqual(entity1, entity2)
}

export function objectDepth (o) {
  return Object(o) === o ? 1 + Math.max(-1, ...Object.values(o).map(objectDepth)) : 0
}

export function processGetParams (rawData) {
  let data = {}
  if (rawData.options) {
    data.pagination = {
      pageNumber: rawData.options.page,
      perPage: rawData.options.itemsPerPage
    }

    if (rawData.options.sortBy && rawData.options.sortBy.length) {
      data.sort = {}
      rawData.options.sortBy.forEach((item, index) => {
        data.sort[item] = rawData.options.sortDesc[index] ? 'DESC' : 'ASC'
      })
    }
  }

  if (rawData.filter) {
    data.filter = rawData.filter //todo: написать обработчик
  }

  if (rawData?.additionalParams) {
    data = { ...data, ...rawData.additionalParams }
  }

  return data
}

// доступ к свойствам объекта obj через строку path типа 'prop1.prop2.prop3', path - полный путь к валидации
export function recLookup (obj, path) {
  // const newResult = _.get(obj, path.split('.')) // note от Вики: посмотри это решение
  if (path === '') {
    return obj
  }
  return _.get(obj, path, null) // note от Вики: посмотри это решение
}

// получаем путь к атрибуту для recLookup из массива индексов
export function getParentPath (indexArray) {
  let path = ''
  if (indexArray.length > 1) {
    path += indexArray[0] + '.relatedAttributes'
    indexArray.slice(1, -1).forEach(item => {
      path += '.' + item + '.relatedAttributes'
    })
  }
  return String(path)
}

// дерево из списка моделей
export function buildTree (data, parentId = null) {
  let tree = data.filter(item => item.parent_id === parentId)
  if (tree.length) {
    data = data.filter(item => item.parent_id !== parentId)
    tree.forEach(item => {
      if (!item.id) {
        throw new Error('item has no id!!!')
      }
      let children = buildTree(data, item.id)
      if (children.length) {
        item.children = children
      }
    })
  }
  return tree
}

function findIdsRecursive (data, ids) {
  let childrenIds = data.filter(item => ids.some(idItem => idItem === item.parent_id)).map(item => item.id)
  if (childrenIds.length) {
    childrenIds = childrenIds.concat(findIdsRecursive(data, childrenIds))
  }
  return childrenIds
}

export function flatenObject (object, childName) {
  let values = []
  object?.forEach(item => {
    values.push(item)
    if (item.hasOwnProperty(childName) && item[childName].length) {
      values = values.concat(flatenObject(item[childName], childName))
    }
  })

  return values
}

export function recDelete (data, id) {
  const idsToDelete = findIdsRecursive(data, [id])
  idsToDelete.push(id)
  return data.filter(item => !idsToDelete.some(idToDelete => idToDelete === item.id))
}

export function currentActiveWithOffset (indexActiveFirst, indexActiveSecond, offset) { // offset - смещение для второго списка
  //используется для определения текущего активного элемента в sidebar, когда списка два. Например, в паспорте и организациях
  if (indexActiveFirst < 0 && indexActiveSecond < 0) {
    return 0
  } else {
    return indexActiveFirst >= 0 ? indexActiveFirst : indexActiveSecond + offset
  }
}

export function collectMenuItems (menuConfig) {
  let menu = []
  menuConfig.forEach(menuConfigItem => {
    if (!menuConfigItem.role || menuConfigItem.role.find(role => role === store.getters['auth/getCurrentRole'])) {
      let menuItem = {}
      if (menuConfigItem.menuItem) {
        const route = router.resolve({ name: menuConfigItem.menuItem })
        if (route) {
          menuItem = {
            title: route.route.meta.title,
            icon: route.route.meta.icon,
            link: route.route.fullPath,
            opacity: route.route.meta.opacity,
            withNotificationIcon: route.route.meta.withNotificationIcon
          }
        }
      } else {
        menuItem = {
          title: menuConfigItem.title,
          subitems: collectMenuItems(menuConfigItem.subitems)
        }
      }
      menu.push(menuItem)
    }
  })
  return menu
}

//фильтр массива по attribute[key], attributes - массив атрибутов ['id', 'owner']
export function filterAttributes (arr, attributes, key = 'name') {
  return _.filter(arr, (attribute) => {
    return !_.find(attributes, (name) => {
      return name === attribute[key]
    })
  })
}

export function sortWithNull (propName = null, asc = true) {
  return (a, b) => {
    let firstEl = propName ? a[propName] : a
    let secondEl = propName ? b[propName] : b
    let result
    if (firstEl === null) {
      result = 1
    } else if (secondEl === null) {
      result = -1
    } else {
      result = (firstEl > secondEl) ? 1 : -1
    }
    return asc ? result : result * -1
  }
}

export function verificationScenarioByRole () {
  const role = store.getters['auth/getCurrentRole']
  let scenario = scenarioOrigin
  if (role === 'unit') {
    scenario = scenarioCommit
  }
  return scenario
}

export function iconObjectStatus (state, verification_status, origin) {
  const stateInOrigin = origin?.properties.state
  const listIcons = {
    'delete': { icon: 'mdi-delete-clock', color: '#AC1919', tip: 'Удаленный' },
    'new': { icon: 'mdi-new-box', color: '#409223', tip: 'Новый' },
    'restored': { icon: 'mdi-restore', color: '#52ca27', tip: 'Восстановленный' },
    'edit': { icon: 'mdi-clock', color: '#DE8C11', tip: 'Измененный' }
  }
  let icon

  if (verification_status === 3) {
    if (!stateInOrigin) {
      // новый объект, нет оригинала
      icon = listIcons.new
    } else if (stateInOrigin === state) {
      //изм копия и есть оригинал
      icon = listIcons.edit
    } else if (state === 2) {
      //удаленный юнитом, есть оригинал
      icon = listIcons.delete
    } else if (state === 1) {
      // восстановление, если копия с оригиналом и поменялся state с 2 на 1
      icon = listIcons.restored
    }
  }

  return icon
}

export function iconsFromAttribute (value) {
  let showInTableString = ''
  const showInTable = value.show_in_table
  const required = value.attributeRule.required
  const locked = value.attributeRule.locked
  const filter = value.is_filter
  const tooltip = value?.is_tooltip

  if (value.relatedAttributes?.length) {
    showInTableString += ': \n'
    value.relatedAttributes.map((item, index) => {
      if (item.show_in_table) {
        showInTableString += item.label + (index === value.relatedAttributes.length - 1 ? '' : ', ')
      }
    })
  }
  return [
    {
      id: 'showInTable',
      icon: 'mdi-eye',
      tip: 'Отображаемый в таблице' + showInTableString,
      show: showInTable
    },
    {
      id: 'required',
      icon: 'mdi-asterisk',
      tip: 'Обязательный',
      show: required
    },
    {
      id: 'locked',
      icon: 'mdi-pencil-off',
      tip: 'Нередактируемый',
      show: locked
    },
    {
      id: 'filter',
      icon: 'mdi-filter',
      tip: 'Фильтруемый',
      show: filter
    },
    {
      id: 'tooltip',
      icon: 'mdi-help-rhombus',
      tip: 'Отображаемый в тултипе',
      show: tooltip
    }
  ]
}

//Определение глубины массива с массивом и возвращение последнего с элементами
export function defineDeepArray (arrWithDepth = { arr: [], depth: 1 }) { //не подходит для мультиполигонов
  let result = { ...arrWithDepth }
  if (Array.isArray(result.arr) && result.arr.length === 1) { //если длина = 1, то это массив с массивом
    result.arr = result.arr[0]
    result.depth += 1
    return defineDeepArray(result)
  } else {
    return result
  }
}

export function getRadiusPoint (zoom) {
  let radius
  if (zoom <= 19) {
    radius = 1
  } else {
    radius = isMobileDevice() ? 9 : 5
  }
  return radius
}

export function checkDateExpired (dateString = '12.10.2024', format = 'DD.MM.YYYY') {
  let result = 'actual'

  const date = moment(dateString, format) //момент из пришедшей даты
  const today = moment()// Сегодняшняя дата
  const daysDifference = date.diff(today, 'days')

  if (daysDifference <= 0) {// Сравнение дат
    result = 'expired'
  } else if (daysDifference <= 30 && daysDifference > 0) {
    result = 'almost-expired'
  }

  return result
}