export default {
  'username': 'ФИО',
  'phone': 'Телефон',
  'email': 'E-mail',
  'online': 'Онлайн',
  'user': 'Пользователь',
  'active': 'Активен',
  'resetPassword': 'Сбросить пароль',
  'changePassword': 'Изменить пароль',
  'oldPassword': 'Старый пароль',
  'newPassword': 'Новый пароль',
  'repeatNewPassword': 'Повторить новый пароль',
  'position': 'Должность',
  'type': 'Тип пользователя',
  'profile': 'Данные профиля',
  'info':'Информация о профиле',

}
