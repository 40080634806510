export default {
  'object':  'Объект',
  'newObject': 'Новый объект',
  'type': 'Тип',
  'name': 'Системное имя',
  'label': 'Название',
  'min': 'Минимальное значение',
  'max': 'Максимальное значение',
  'attribute':  'Атрибут',
  'baseAttributes': 'Базовые атрибуты',
  'groupAttributes': 'Группа атрибутов',
  'customAttributes': 'Дополнительные атрибуты',
  'objectName': 'Имя объекта',
  'required': 'Обязательный',
  'showInTable': 'Отображать в таблице',
  'showInTooltip': 'Отображать в тултипе',
  'filter': 'Фильтр',
  'locked': 'Нередактируемый',
  'primary': 'Первичный',
  'style': 'Цвет и Иконки',
  'icon': 'Иконка',
  'color': 'Цвет',
  'order': 'Порядок отображения в таблице',
  'editAttrs': 'Редактировать атрибуты'
}
