<template>
  <v-app class="app">
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import '@/css/common.css'

export default {
  name: 'App',
  components: {
    AccountLayout: () => import('@/layouts/AccountLayout'),
    AuthLayout: () => import('@/layouts/AuthLayout'),
    MobileLayout: () => import('@/layouts/MobileLayout'),
    ErrorsLayout: () => import('@/layouts/ErrorsLayout')
  },
  computed: {
    layout () {
      return (this.$route.meta.layout)
    }
  }
}
</script>

<style lang="scss">
  @import '~@/sass/variables.scss';
  .links {
    text-decoration: unset;
    color: $light-breeze !important;
  }
</style>

<style scoped>
  .app, /deep/ .v-application--wrap {
    min-height: 100% !important;
    height: 100% !important;
  }
</style>

<style>
  body, html {
    height: 100%;
    margin: 0;
    padding: 0;
  }
</style>
