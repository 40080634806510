<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="false"
    :return-value="value"
    @update:return-value="updateMenu"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="date"
        @keyup.enter="handleSave(date)"
        :label="label"
        :disabled="disabled"
        prepend-icon="mdi-calendar"
        readonly
        v-on="on"
        @click:clear="$emit('input', null)"
        clearable
        outlined
        dense
        :error-messages="errorMessages"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="activeDate"
      no-title
      scrollable
      locale="ru-RU"
      @keyup.native.enter="handleSave(date)"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="handleCancel"
      >Закрыть
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="handleSave(date)"
      >OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import formatters from '@/components/mixins/formatters'

export default {
  mixins: [formatters],
  name: 'BaseDatePicker',
  props: {
    value: [Number, String],
    label: String,
    errorMessages: Array,
    disabled: Boolean
  },
  data: function () {
    return {
      menu: false,
      date: this.timestampToIso()
    }
  },
  computed: {
    activeDate: {
      get () {
        return this.dateToIso(this.date)
      },
      set (val) {
        if (!val) return null
        const [year, month, day] = val.split('-')
        this.date = `${day}.${month}.${year}`
      }
    }
  },
  methods: {
    updateMenu (event) {
      if (typeof event === 'string') {
        this.$emit('input', this.dateToTimestamp(this.dateToIso(event)))
      } else {
        this.$emit('input', this.dateToTimestamp(this.dateToIso(this.date)))
      }
    },
    handleSave (data) {
      this.$refs.menu.save(data)
    },
    handleCancel () {
      this.date = this.timestampToIso()
      this.$refs.menu.save(this.date)
      this.menu = false
    },
    timestampToIso () {
      let value = null
      if (this.value) {
        let date = new Date(this.value * 1000)
        const [month, day, year] = date.toLocaleDateString('en-EN').split('/')
        value = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
      }
      return value
    },
    dateToIso (data) {
      let result = null
      if (data && typeof data === 'string') {
        const [day, month, year] = data.split('.')
        result = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }
      return result
    }
  }
}
</script>

<style scoped>

</style>
