import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
import store from '@/store'
import _ from 'lodash'
import moment from 'moment'


//note: ВЗАИОДЕЙСТВИЕ С ФАЙЛОВОЙ СИСТЕМОЙ
export const writeSystemFile = async (path = 'map/', data) => {
  await Filesystem.writeFile({
    path,
    data: JSON.stringify(data),
    directory: Directory.Data,
    encoding: Encoding.UTF8,
    recursive: true // создать папку, если её нет
  })
    .catch((e) => store.dispatch('systemMessages/error', { text: 'Ошибка: ' + e }))
  return true
}

export const readSystemFile = async (path) => {
  const result = await Filesystem.readFile({
    path: path,
    directory: Directory.Data,
    encoding: Encoding.UTF8
  }).catch(() => '')
  return result ? result?.data : null
}

export const readSystemFilesInFolder = async (path) => {
  const result = await Filesystem.readdir({
    path,
    directory: Directory.Data
  }).catch(() => '')
  return result
}

export const deleteSystemFolder = async (path) => {
  const result = await Filesystem.rmdir({
    path,
    directory: Directory.Data,
    recursive: true // удаляет содержимое папки
  }).catch(() => '')
  return result
}

export const deleteSystemFile = async (path) => {
  await Filesystem.deleteFile({
    path,
    directory: Directory.Data
  })
  return true
}


// note: ВЗАИМОДЕЙСТВИЕ С ДАННЫМИ
const baseMapPath = 'map/passport/'
export const getOriginObject = async (passportId, objectId) => {
  const pathOrigin = baseMapPath + passportId + '/objects/' + objectId
  const originFile = await readSystemFile(pathOrigin)
  return originFile && JSON.parse(originFile)
}

export const deleteOriginObject = async (passportId, objectId) => {
  const pathOrigin = baseMapPath + passportId + '/objects/' + objectId
  if (await getOriginObject(passportId, objectId)) {
    await deleteSystemFile(pathOrigin)
  }
}

export const writeOriginObject = async (passportId, objectId, data) => {
  const pathOrigin = baseMapPath + passportId + '/objects/' + objectId
  await writeSystemFile(pathOrigin, data)
}

export const getCopyObject = async (passportId, objectId) => {
  const pathCopy = baseMapPath + passportId + '/objects/copies/' + objectId
  return JSON.parse(await readSystemFile(pathCopy))
}

export const deleteCopyObject = async (passportId, objectId) => {
  const pathCopy = baseMapPath + passportId + '/objects/copies/' + objectId
  if (await getCopyObject(passportId, objectId)) {
    await deleteSystemFile(pathCopy)
  }
}

export const writeCopyObject = async (passportId, objectId, data) => {
  const pathCopy = baseMapPath + passportId + '/objects/copies/' + objectId
  await writeSystemFile(pathCopy, data)
}

export const writeNewObject = async (passportId, data, field) => { //data - объект. это новые созданные объекты
  const propsData = _.cloneDeep(data)
  if(!propsData.properties.id) propsData.properties.id = Date.now()
  //добавление в файл нью
  const pathNew = baseMapPath + passportId + '/objects/new/new'
  const newObjects = await getNewObjects(passportId) //файл с новыми объектами
  let result = []
  if (newObjects?.length) {
    result = newObjects
  }
  const findSameObject = result.findIndex((item) => item?.properties?.id === propsData.properties.id) // for двойного сохранения
  if (!(findSameObject >= 0)) { //если объекта нет, то мы его добавим
    result.push(propsData)
  } else { //а иначе перезапишем
    if (field) {
      result[findSameObject][field] = propsData[field]
    } else {
      result[findSameObject] = propsData
    }
  }
  await writeSystemFile(pathNew, result)

  //добавление в модели
  const pathModel = 'map/passport/' + propsData.properties.passport_id + '/models/' + propsData.properties.passport_id
  const modelObject = JSON.parse(await readSystemFile(pathModel))

  if (!modelObject.models[propsData.geometry.type]) { // если нет такого ключа по гео, т.е. Point, Polygon, то надо создать
    modelObject.models[propsData.geometry.type] = {}
  }
  if (!modelObject.models[propsData.geometry.type][propsData.properties.eav_entity_id]) { //тоже самое с типом по id (43,32...)
    modelObject.models[propsData.geometry.type][propsData.properties.eav_entity_id] = []
  }

  if (!(findSameObject >= 0)) {
    modelObject.models[propsData.geometry.type][propsData.properties.eav_entity_id].push(propsData)
  } else {
    const indexExistingObject = modelObject.models[propsData.geometry.type][propsData.properties.eav_entity_id].findIndex((item) => item?.properties?.id === propsData.properties.id)
    const wasPolygon = _.size(modelObject.models) && modelObject.models.hasOwnProperty('Polygon') && propsData.geometry.type === 'MultiPolygon' && !(indexExistingObject >= 0)
    if(!wasPolygon) modelObject.models[propsData.geometry.type][propsData.properties.eav_entity_id][indexExistingObject] = propsData
    else {
      modelObject.models['Polygon'][propsData.properties.eav_entity_id].splice(indexExistingObject, 1)
      modelObject.models['MultiPolygon'][propsData.properties.eav_entity_id].push(propsData)
    }
  }

  await writeSystemFile(pathModel, modelObject)
  return true
}

export const getNewObjects = async (passportId) => {
  const pathNew = baseMapPath + passportId + '/objects/new/new'
  const newObject = await readSystemFile(pathNew)
  if (newObject) {
    return JSON.parse(newObject)
  }
}

export const deleteNewObject = async (passportId, data) => {
  const pathNew = baseMapPath + passportId + '/objects/new/new'
  let newObject = await getNewObjects(passportId)
  if (newObject) {
    const findObject = newObject
      .findIndex((item) => item?.properties?.id === data.properties.id)
    newObject?.splice(findObject, 1)
    await writeSystemFile(pathNew, newObject)
  }
}

export const getFromModel = async (passportId) => {
  const pathModel = baseMapPath + passportId + '/models/' + passportId
  return JSON.parse(await readSystemFile(pathModel))
}

export const deleteFromModel = async (passportId, object) => {
  const pathModel = baseMapPath + passportId + '/models/' + passportId
  const model = JSON.parse(await readSystemFile(pathModel))
  const indexObjectInModel = model.models[object.geometry.type][object.properties.eav_entity_id]
    .findIndex((item) => item.properties.id === object.properties.id)
  model.models[object.geometry.type][object.properties.eav_entity_id].splice(indexObjectInModel, 1)
  await writeSystemFile(pathModel, model)
}

export const changeModel = async (object) => {
  const pathModel = baseMapPath + object.properties.passport_id + '/models/' + object.properties.passport_id
  const model = await getFromModel(object.properties.passport_id)
  let changedElement = model.models[object.geometry.type][object.properties.eav_entity_id]?.findIndex((item) => item?.properties?.id === object?.properties?.id)
  model.models[object.geometry.type][object.properties.eav_entity_id][changedElement] = object
  await writeSystemFile(pathModel, model)
  return true
}

export const writeRemovedObject = async (passportId, objectId, data) => {
  const pathRemove = baseMapPath + passportId + '/objects/removed/' + objectId
  await writeSystemFile(pathRemove, data)
}

export const getRemovedObjects = async (passportId) => {
  const pathRemove = baseMapPath + passportId + '/objects/removed'
  return await readSystemFilesInFolder(pathRemove)
}

export const getRemovedObject = async (passportId, objectId) => {
  const pathRemove = baseMapPath + passportId + '/objects/removed/' + objectId
  return await readSystemFile(pathRemove)
}

export const getSystemPhotos = async (passportId, objectId) => {
  let result = []
  const newObjects = await getNewObjects(passportId)
  const findSameObject = newObjects?.findIndex((item) => item?.properties?.id === objectId)
  if (!(findSameObject >= 0)) {//если не новый, берем копию
    const pathCopyPhoto = baseMapPath + passportId + '/objects/copies/images/' + objectId
    await readSystemFile(pathCopyPhoto) &&
    (result = JSON.parse(await readSystemFile(pathCopyPhoto)))
  } else {
    result = newObjects[findSameObject]?.properties?.objectImages
  }
  return result
}

export const deleteSystemPhoto = async (passportId, objectId, idImage) => {
  const pathCopyPhoto = baseMapPath + passportId + '/objects/copies/images/' + objectId
  const photos = await getSystemPhotos(passportId, objectId)
  if (photos) {
    const parsePhotos = photos
    const index = parsePhotos.findIndex(item => item.image.id === idImage)
    parsePhotos.splice(index, 1)
    await writeSystemFile(pathCopyPhoto, parsePhotos)
  }
}

export const writeSystemPhoto = async (passportId, objectId, data, newObject) => {
  const pathCopyPhoto = baseMapPath + passportId + '/objects/copies/images/' + objectId
  let photos = await getSystemPhotos(passportId, objectId)
  if (!photos) {
    photos = []
  }

  await Promise.all(data.map(async (item) => {
    if (item) {
      await readFileAsync(item).then((res) => {
        photos.push({
          image: {
            path: res,
            id: res.slice(10) + Date.now(),
            file_name: item.name,
            description: '',
            created_at: moment().unix()
          },
          isEditModeDescription: false,
          isSystem: true
        })
      })
    }
  }))

  let localNewObject = newObject
  if (!localNewObject) {
    const newObjects = await getNewObjects(passportId)
    const newObjectIndex = newObjects?.findIndex(item => item.properties.id === objectId)
    localNewObject = newObjectIndex >= 0 ? newObjects[newObjectIndex] : null
  }
  if (!(localNewObject)) {
    await writeSystemFile(pathCopyPhoto, photos)
  } else {
    await addSystemPhotoInNew(passportId, photos, objectId, localNewObject)
  }
}

export const addSystemPhotoInNew = async (passportId, photos, objectId, newObject) => {//для добавления в новые объекты
  let localNewObject = newObject
  if (!newObject) {
    const newObjects = await getNewObjects(passportId)
    const findSameObject = newObjects.findIndex((item) => item?.properties?.id === objectId)
    localNewObject = newObjects[findSameObject]
  }
  localNewObject.properties.objectImages = photos
  await writeNewObject(passportId, localNewObject, 'properties')
}

const readFileAsync = (selectedFile) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      resolve(base64String)
    }
    reader.readAsDataURL(selectedFile)
  })
}

export const changeSystemPhoto = async (passportId, objectId, data) => { //для добавления/изменения описания
  const pathCopyPhoto = baseMapPath + passportId + '/objects/copies/images/' + objectId
  const photos = await getSystemPhotos(passportId, objectId)
  const copyData = _.cloneDeep({ ...data })
  copyData.isEditModeDescription = false //закрыть редактирование описания
  const newObjects = await getNewObjects(passportId)
  const findSameObject = newObjects?.findIndex((item) => item?.properties?.id === objectId)
  if (!(findSameObject >= 0)) { //для серверного
    const parsePhotos = photos
    const index = parsePhotos.findIndex(item => item.image.id === copyData.image.id)
    parsePhotos.splice(index, 1, copyData)
    await writeSystemFile(pathCopyPhoto, parsePhotos)
  } else if (findSameObject >= 0) { //для нового
    const indexPhoto = newObjects[findSameObject].properties.objectImages.findIndex(item => item.image.id === copyData.image.id)
    newObjects[findSameObject].properties.objectImages.splice(indexPhoto, 1, copyData)
    await writeNewObject(passportId, newObjects[findSameObject], 'properties')
  }
}
