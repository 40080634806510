export default {
  'fromDate': 'Дата начала',
  'toDate': 'Дата окончания',
  'price': 'Цена',
  'subscriptionPassports': 'Список паспортов',
  'rate': 'Тариф',
  'passportList': 'Список паспортов',
  "choosePassportList": 'Выберите список паспортов',
  'status': 'Статус'
}
