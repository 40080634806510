import { buildTree, cleanClone, recDelete } from '@/components/utils/common'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import { readSystemFile, writeSystemFile } from '@/components/utils/mobile/filesystemInteraction'
import { isNeedOfflineMode, isOffline } from '@/components/utils/mobile/common'

export function mixin () {
  return {
    state: {
      listUrl: '',
      detailUrl: '',
      name: '',
      loadingState: false,
      list: [],
      tree: []
    },
    mutations: {
      setLoadingState (state, payload) {
        state.loadingState = payload
      },
      async setCategory (state, payload) {
        state.list = payload
        state.tree = buildTree(cleanClone(state.list))
        if (isMobileDevice()) {
          await writeSystemFile('treeMixin/list', state.list)
          await writeSystemFile('treeMixin/tree', state.tree)
        }
      },
      async setCategoryOffline (state, payload) {
        state.tree = JSON.parse(await readSystemFile('treeMixin/tree')) || []
        state.list = JSON.parse(await readSystemFile('treeMixin/list')) || []
      },
      updateCategory (state, payload) {
        const index = state.list.findIndex(item => item.id === payload.id)
        if (index > -1) {
          state.list[index] = payload
        } else {
          state.list.push(payload)
        }
        state.tree = buildTree(cleanClone(state.list))
      },
      deleteCategory (state, payload) {
        state.list = recDelete(cleanClone(state.list), payload)
        state.tree = buildTree(cleanClone(state.list))
      }
    },
    actions: {
      transformData ({}, data) {
        return data
      },
      async fetch ({ getters, commit, dispatch }) {
        commit('setLoadingState', true)
        if (!await isNeedOfflineMode()) {
          const response = await dispatch('api/get', { url: getters['listUrl'] }, { root: true })
          if (response.data) {
            let data = []
            if (Array.isArray(response.data)) {
              data = response.data
            } else {
              for (let item in response.data.models) {
                data.push(await dispatch('transformData', response.data.models[item]))
              }
            }
            commit('setCategory', data)
          }
        } else {
         commit('setCategoryOffline')
        }

        commit('setLoadingState', false)
        return true
      },
      async updateCategory ({ getters, commit, dispatch }, payload) {
        commit('setLoadingState', true)
        let categoryToUpdate = null
        const response = await dispatch('server/save', {
          url: getters['detailUrl'],
          data: { data: payload }
        }, { root: true })
        if (response) {
          categoryToUpdate = await dispatch('transformData', response)
          commit('updateCategory', categoryToUpdate)
        }
        commit('setLoadingState', false)
        return categoryToUpdate
      },
      async deleteCategory ({ getters, commit, dispatch }, payload) {
        commit('setLoadingState', true)
        const response = await dispatch('server/delete', { url: getters['detailUrl'] + '/' + payload }, { root: true })
        if (response) {
          commit('deleteCategory', payload)
        }
        commit('setLoadingState', false)
        return response
      }
    },
    getters: {
      listUrl: state => state.listUrl,
      detailUrl: state => state.detailUrl,
      isLoading: state => state.loadingState,
      getList: state => state.list,
      getTree: state => state.tree,
      getListItemById: state => id => state.list.find(item => item.id === id)
    }
  }
}
