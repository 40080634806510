export default [
  {
    path: '*',
    name: 'pageNotFound',
    meta: {
      title: 'Страница не найдена',
      layout: 'ErrorsLayout',
      auth: false,
    },
    component: () => import('../components/views/account/PageNotFound.vue')
  },
]