import { recLookup } from '@/components/utils/common'
//todo: сделать сообщения через $t
export default {
  methods: {
    checkErrors (propName) {
      const errors = []
      const validationObject = recLookup(this.$v, propName)
      if (!validationObject) {
        console.log(`Failed to find path: ${propName} in validation object`)
        return errors
      }
      if (!validationObject.$dirty) return errors
      if (validationObject.hasOwnProperty('required')) {
        !validationObject.required && errors.push('Необходимо заполнить поле')
      }
      if (validationObject.hasOwnProperty('minValue')) {
        !validationObject.minValue && errors.push('Поле должно быть больше ' + validationObject.$params.minValue.min)
      }
      if (validationObject.hasOwnProperty('maxValue')) {
        !validationObject.maxValue && errors.push('Поле должно быть меньше ' + validationObject.$params.maxValue.max)
      }
      if (validationObject.hasOwnProperty('email')) {
        !validationObject.email && errors.push('Введите корректный email')
      }
      if (validationObject.hasOwnProperty('minLength')) {
        !validationObject.minLength && errors.push('Поле должно иметь не менее ' + validationObject.$params.minLength.min + ' символов')
      }
      if (validationObject.hasOwnProperty('maxLength')) {
        !validationObject.maxLength && errors.push('Поле должно иметь не более ' + validationObject.$params.maxLength.max + ' символов')
      }
      if (validationObject.hasOwnProperty('sameAsPassword')) {
        !validationObject.sameAsPassword && errors.push('Поле должно совпадать с новым паролем')
      }
      if (validationObject.hasOwnProperty('isUnique')) {
        !validationObject.isUnique && !validationObject.$pending && errors.push('Поле должно быть уникальным')
      }
      if (validationObject.hasOwnProperty('numeric')) {
        !validationObject.numeric && errors.push('Поле должно содержать только цифры')
      }
      if (validationObject.hasOwnProperty('validCoordinates')) {
        !validationObject.validCoordinates && errors.push('Введен невеверный формат координат')
      }
      if (validationObject.$anyError) {
        errors.push('Ошибка валидации')
      }
      return errors
    },
    async checkPending () {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          if (this.$v.$pending) {
            await this.checkPending()
          }
          resolve(true)
        }, 2000)
      })
    }
  }
}
