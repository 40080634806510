import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from '@/store/auth'
import api from '@/store/api'
import systemMessages from '@/store/systemMessages'
import server from '@/store/server'
import handbook from '@/store/handbook'
import serviceData from '@/store/serviceData'
import report from '@/store/report'
import region from '@/store/region'
import verification from '@/store/verification'
import map from '@/store/map'
import queueDownload from '@/store/mobile/queueDownload'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      name: 'greenreen',
      paths: ['auth.jwt', 'auth.profile', 'auth.currentRole', 'serviceData.storyRoute']
    })
  ],
  modules: {
    auth,
    api,
    systemMessages,
    server,
    handbook,
    serviceData,
    report,
    region,
    verification,
    map,
    queueDownload
  }
})
