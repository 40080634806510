import Polygon from '@/components/structures/geometry/polygon'

class MultiPolygon {
  constructor (coordinatesString = null) {
    this.type = 'MultiPolygon'
    this.coordinates = [[[[]]]]
    if (coordinatesString) {
      if (Array.isArray(coordinatesString)) {
        this.coordinates = coordinatesString
      } else {
        this.coordinates = coordinatesString.split('\n\n\n').map(polygon => (new Polygon(polygon)).coordinates)
      }
    }
  }

  validate () {
    let valid = true
    if (!this.coordinates.length || this.coordinates.some(polygon => !(new Polygon(polygon).validate()))) {
      valid = false
    }
    return valid
  }

  toString () {
    return this.coordinates.map(polygon => (new Polygon(polygon).toString())).join('\n\n\n')
  }
}

export default MultiPolygon
