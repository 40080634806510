<template>
  <!--<v-card
    v-show="false"
    falt
    outlined
    class="mb-2"
  >
    <v-card-title v-if="$route.name !== 'map'">{{label}}</v-card-title>
    <v-card-text>
      <v-select
        class="mr-3"
        dense
        outlined
        v-model="geometry.type"
        :disabled="this.$route.name === 'map'"
        :items="geometryTypes"
        item-text="label"
        item-value="name"
        :error-messages="checkErrors('detail.geometry.type')"
        :prepend-inner-icon="prependInnerIcon"
        label="Картографический тип"
      ></v-select>
      <BaseCoordinates
        class="mr-3"
        v-model="geometry"
        :error-messages="checkErrors('detail.geometry.coordinates')"
        label="Координаты"
        :prepend-inner-icon="prependInnerIcon"
      />
    </v-card-text>
  </v-card>-->
</template>

<script>
import BaseCoordinates from '@/components/base/BaseCoordinates'
import { cleanClone, deepEqual } from '@/components/utils/common'
import { geometryTypes } from '@/config/common'
import validationErrors from '@/components/mixins/validationErrors'

export default {
  mixins: [validationErrors],
  name: 'BuilderGeometry',
  components: { BaseCoordinates },
  props: {
    value: Object,
    disabled: Boolean,
    label: String,
    prependInnerIcon: String
  },
  data () {
    return {
      geometry: cleanClone({ type: this.value.geometryType, coordinates: this.value.coordinates })
    }
  },
  inject: ['$v'],
  computed: {
    geometryTypes () {
      return geometryTypes
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (!deepEqual(newVal, oldVal)) {
          this.geometry = cleanClone({ type: this.value.geometryType, coordinates: this.value.coordinates })
        }
      },
      deep: true
    },
    'geometry.type': {
      handler (newVal) {
        if (newVal === 'Point') {
          this.geometry.coordinates = []
        } else if (newVal === 'LineString') {
          this.geometry.coordinates = [[]]
        } else if (newVal === 'Polygon') {
          this.geometry.coordinates = [[[]]]
        }
        this.$nextTick(() => this.emitInput())
      }
    },
    'geometry.coordinates': {
      handler () {
        this.emitInput()
      },
      deep: true
    }
  },
  methods: {
    emitInput () {
      this.$emit('input', { geometryType: this.geometry.type, coordinates: this.geometry.coordinates })
    }
  }
}
</script>

<style scoped>

</style>
