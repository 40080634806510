<template>
  <v-text-field
    :label="label"
    dense
    v-model="model"
    outlined
    :clearable="clearable"
    :disabled="disabled"
    :prepend-inner-icon="prependInnerIcon"
    :error-messages="errorMessages"
    :hide-details="!errorMessages || !errorMessages.length"
    :class="classText"
    @input="model = $event"
  ></v-text-field>
</template>

<script>
import builders from '@/components/views/account/passport/detail/formBuilder/builders'

export default {
  name: 'BuilderTextField',
  mixins: [builders],
  props: {
    label: String,
    dense: Boolean,
    clearable: Boolean,
    disabled: Boolean,
    prependInnerIcon: String,
    errorMessages: Array,
    value: [String, Number],
    name: String
  },
  data () {
    return {
      model: this.value
    }
  },
  watch: {
    model: {
      handler: function () {
        this.$emit('input', this.model)
      },
      deep: true
    }
  },
  methods: {
    checkFieldRoute () {
      if (this.$route.name !== 'map' && this.currentItemFilter) {
        this.model = this.currentItemFilter
      } else if (!!this.$route.query?.filter && Object.keys(this.$route?.query).length !== 0) {
        this.model = this.$route.query.filter[this.name]
      } else if (this.value) {
        this.model = this.value
      }
    }
  },
  mounted () {
    this.checkFieldRoute()
  }
}
</script>