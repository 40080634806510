export default [
  {
    path: '/mobile',
    name: 'mobileHome',
    meta: {
      title: 'Домашняя страница',
      layout: 'MobileLayout',
      auth: false
    },
    component: () => import('../components/views/mobile/auth/Home')
  },
  {
    path: '/mobile/login',
    name: 'mobileLogin',
    meta: {
      title: 'Вход',
      layout: 'MobileLayout',
      auth: false
    },
    component: () => import('../components/views/mobile/auth/Login')
  },
  {
    path: '/mobile/recovery-password',
    name: 'mobileRecoveryPassword',
    meta: {
      title: 'Восстановление пароля',
      layout: 'MobileLayout',
      auth: false
    },
    component: () => import('../components/views/mobile/auth/RecoveryPassword')
  },
  {
    path: '/mobile/map',
    name: 'mobileMap',
    meta: {
      title: 'Карта',
      layout: 'MobileLayout',
      auth: true,
      icon: 'mdi-map'
    },
    component: () => import('../components/views/mobile/map/Detail.vue')
  }
]
