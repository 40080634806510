import LineString from '@/components/structures/geometry/lineString'
import Point from '@/components/structures/geometry/point'
import Polygon from '@/components/structures/geometry/polygon'
import MultiPolygon from '@/components/structures/geometry/multiPolygon'

class GeometryFactory {
  constructor (geometry = null) {
    if (!geometry) {
      geometry = { type: 'MultiPolygon', coordinates: null }
    }
    let factoryClass = null
    switch (geometry.type) {
      case 'Point':
        factoryClass = Point
        break
      case 'LineString':
        factoryClass = LineString
        break
      case 'Polygon' :
        factoryClass = Polygon
        break
      case 'MultiPolygon' :
        factoryClass = MultiPolygon
        break
    }
    if (!factoryClass) {
      throw new Error('Failed to find class: ' + name)
    }
    return new factoryClass(geometry.coordinates)
  }
}

export default GeometryFactory
