<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        icon
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          small
          icon
        >mdi-help-circle-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card max-width="200">
      <v-card-text> {{text}}</v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseHint',
  props: {
    text: String
  }
}
</script>

<style scoped>

</style>
