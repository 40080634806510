export default {
  'resetYourPassword': 'Вы действительно хотите сбросить пароль для пользователя',
  'addObject': 'Добавьте к паспорту объекты',
  'selectObject': 'Укажите объекты для данного паспорта',
  'enterName': 'Название объекта',
  'chooseColor': 'Укажите цвет',
  'chooseIcon': 'Укажите иконку',
  'enterAttr': 'Введите название атрибута',
  'enterNameHandbook': 'Введите название справочника',
  'confirm': 'Подтвердить данные паспорта ?',
  'sendConfirm': 'Отправить данные паспорта на подтверждение?',
  'rejection': 'Укажите причину отказа',
  'noObjects': 'Нет назначенных объектов',
  'noPhoto': 'Нет фотографий',
  'noDocs': 'Нет файлов',
  'noChanges': 'Нет изменений',
  'noPersons': 'Нет ответственных лиц',
  'noSubscriptionPassport': 'Не выбраны паспорта',
  'addList': 'Добавить значения списком',
  'enterList': 'Каждое значение должно быть с новой строки',
  'noList': 'Нет значений списка',
  'areYouSure': 'Вернуть данные после удаления будет невозможно',
  'deletePassport': 'Вы действительно хотите удалить этот паспорт ?',
  'deleteObject' : 'Вы действительно хотите удалить этот объект ?',
  'deleteRegion': 'Вы действительно хотите удалить этот регион?',
  'createAttr': 'Создайте атрибут для этого объекта',
  'reportTemplateNoName': 'Если название шаблона не задать, то по умолчанию шаблон называется «Новый шаблон n++»',
  'shouldDeleteLists': 'Сначала удалите связанные списки',
  'conditionCreateComplexHandbook': 'Составной список может быть добавлен, если указано название и добавлено минимум два простых списка.',
  'choiceAttr': 'Выберите атрибут(ы) для скачивания',
  'typeReportName': 'Введите название отчета',
  'successDeletePhoto': 'Фото успешно удалено',
  'successLoadPhoto': 'Фото успешно загружено',
  'hereNothingShow': 'Здесь нечего показать',
  'continue': 'Продолжить',
  'deleteReportType': 'Все объекты в отчете по текущему типу объекта будут удалены.',
  'noCorrectCoordinates': 'Некорректные координаты'
}
