export default {
  'name': 'Организация',
  'address': 'Юридический адрес',
  'region': 'Регион',
  'city': 'Город',
  'role': 'Роль',
  'company': "Компания",
  'requisite':'Реквизиты',
  'inn': 'ИНН',
  'kpp': 'КПП',
  'ogrn': 'ОГРН',
  'checkingAccount': 'Р_сч',
  'correspondentAccount': 'Кор_счет',
  'bank': 'Банк',
  'chooseRole': 'Выбрать роль',
  'bik': 'БИК',
  'note': 'Примечание'
}
