export default [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Домашняя страница',
      layout: 'AuthLayout',
      auth: false
    },
    component: () => import('@/views/public/Home')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Вход',
      layout: 'AuthLayout',
      auth: false
    },
    component: () => import('@/views/public/Login')
  },
  {
    path: '/recovery-password',
    name: 'recoveryPassword',
    meta: {
      title: 'Восстановление пароля',
      layout: 'AuthLayout',
      auth: false
    },
    component: () => import('@/views/public/RecoveryPassword')
  },
  {
    path: '/registration',
    name: 'registration',
    meta: {
      layout: 'AuthLayout',
      auth: false
    },
    component: () => import('@/views/public/Registration')
  },
  {
    path: '/auth-key',
    name: 'authKey',
    meta: {
      layout: 'AuthLayout',
      auth: false
    },
    component: () => import('@/views/public/KeyVerification')
  }
]
