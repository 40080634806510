import { mixin } from './mixin/treeMixin'

export default {
  namespaced: true,
  state: {
    ...mixin().state,
    listUrl: '/region',
    detailUrl: '/region/detail',
    name: 'region'
  },
  mutations: {
    ...mixin().mutations
  },
  actions: {
    ...mixin().actions,
    transformData ({}, data) {
      if (data.properties) {
        return { ...data.properties }
      } else {
        return data
      }
    }
  },
  getters: {
    ...mixin().getters,
    getListItemById: state => id => state.list.find(item => item.id === id),
    getRegionFullLabel: (state, getters) => region => {
      let fullLabel = region.label
      while (region.parent_id) {
        let parentRegion = getters['getListItemById'](region.parent_id)
        fullLabel += ' / ' + parentRegion.label
        region = parentRegion
      }
      return fullLabel
    },
    getIdsByParentId: (state) => (parentId) => {
      return state.list
        .filter((item) => item.parent_id === parentId)
        .map((item) => item.id)
    }
  }
}
