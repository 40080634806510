import { Network } from '@capacitor/network'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'

export const isOffline = async () => {
  const status = await Network.getStatus()
  return !status?.connected
}

export const isNeedOfflineMode = async () => {
  return await isOffline() && isMobileDevice()
}