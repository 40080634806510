export default [
  {
    menuItem: 'dashboard',
    role: ['unit', 'auditor', 'observer', 'manager']
  },
  {
    menuItem: 'region',
    role: ['admin']
  },
  {
    menuItem: 'passport',
    role: null
  },
  {
    menuItem: 'map',
    role: null
  },
  {
    menuItem: 'handbook',
    role: ['admin']
  },
  {
    menuItem: 'organization',
    role: ['admin']
  },
  {
    menuItem: 'user',
    role: ['admin', 'manager']
  },
  {
    menuItem: 'subscription',
    role: ['admin']
  },
  {
    menuItem: 'appeal',
    role: ['admin']
  },
  {
    menuItem: 'requestFellingTicket',
    role: ['admin']
  },
  {
    menuItem: 'fellingTicket',
    role: ['admin']
  },
  {
    menuItem: 'constructor',
    role: ['admin']
  },
  {
    menuItem: 'help',
    role: null
  },
  {
    menuItem: 'verification',
    role: ['admin', 'auditor', 'unit']
  }
]
