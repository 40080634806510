import { recLookup } from '@/components/utils/common'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import {
  readSystemFile,
  readSystemFilesInFolder,
  writeSystemFile
} from '@/components/utils/mobile/filesystemInteraction'
import { isNeedOfflineMode, isOffline } from '@/components/utils/mobile/common'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    loadingState: false,
    data: {},
    countOfVerifications: 0,
    storyRoute: ''
  },
  mutations: {
    setLoadingState (state, payload) {
      state.loadingState = payload
    },
    async setData (state, payload) {
      state.data = payload
      if (isMobileDevice() && !await isOffline()) {
        const service = await readSystemFilesInFolder('serviceData')
        const millisec = service && service?.files[0]?.ctime
        const today = Date.now()
        const difference = moment(today - millisec).minutes() // минуты с момента последнего обновления сервис даты
        if (difference >= 5) {
          await writeSystemFile('serviceData/data', payload)
        }
      }
      return true
    },
    setCountOfVerifications (state, payload) {
      state.countOfVerifications = payload
    },
    setStoryRoute (state, payload) {
      state.storyRoute = payload
    }
  },
  actions: {
    async fetch ({ commit, dispatch }) {
      commit('setLoadingState', true)
      if (!await isNeedOfflineMode()) {
        const response = await dispatch('server/get', { url: '/service-data' }, { root: true })
        if (response) {
          commit('setData', response)
        }
      } else if (isMobileDevice()) {
        const service = await readSystemFile('serviceData/data')
        commit('setData', JSON.parse(service))
      }
      commit('setLoadingState', false)
      return true
    },
    async verificationCheck ({ commit, dispatch }) {
      const response = await dispatch('server/get', {
        url: '/verification/check'
      }, { root: true })
      if (response) commit('setCountOfVerifications', response)
      return true
    }
  },
  getters: {
    get: state => path => recLookup(state.data, path),
    optionValue: (state, getters) => (path, value) => getters.get(path)?.find(item => item.value === value)?.label,
    isLoading: state => state.loadingState,
    countOfVerifications: state => state.countOfVerifications,
    storyRoute: state => state.storyRoute,
    getAll: state => state.data
  }
}
