export default {
  'address': 'Адрес',
  'area': 'Площадь кв.м',
  'createPassport': 'Создание паспорта',
  'canVerify': 'Может быть верифицировано',
  'deletePassport': 'Удалить паспорт',
  'deleteRegion': 'Удалить субъект',
  'element_number': 'Номер Элемента',
  'passportDetails': 'Реквизиты паспорта',
  'id': 'Инвентарный номер',
  'cadastr_number': 'Кадастровый номер',
  'objectName': 'Наименование паспорта',
  'territorial_affiliation': 'Административно территориальная принадлежность',
  'executing_organization': 'Организация выполняющая паспортизацию',
  'classification_code': 'Классификационный код',
  'classification_code_detail': 'Расшифровка классификационного кода',
  'land_function': 'Функциональное назначение земель',
  'owner': 'Ответственный владелец',
  'object_name': 'Название объекта',
  'using_mode': 'Режимы охраны и использования',
  'length': 'Длина участка пг.м',
  'width': 'Ширина участка м',
  'general': 'Общие данные',
  'dashboardPassport': 'Паспорт',
  'export': 'Экспорт',
  'map': 'Работа с картой',
  'reports': 'Отчеты',
  'info': 'Информация о паспорте',
  'responsiblePersons': 'Ответственные лица',
  'region': 'Регион',
  'personType': 'Тип ответственного лица',
  'outEditPassport': 'Выйте из паспорта',
  'templateName': 'Название шаблона',
  'docs': 'Перечень документов, приложенных к паспорту',
  'media': 'Материалы фотофиксации (фото, видео, и т п)',
  'downloadPassport': 'Скатчать паспорт',
  'objectList': 'Типы объектов паспорта',
  'closePassport': 'Закрыть вкладку паспорта',
  'closeObject': 'Закрыть вкладку объекта',
  'colorOnMap': 'Цвет на карте',
  'passportImages': 'Изображения',
  'type': 'Тип паспорта',
  'origin_executor':  'Исполнитель первичной паспортизации',
  'origin_passportization_date':  'Дата первичной паспортизации',
  'person_in_charge': 'Ответственное лицо',
  'reportByPassport': 'Отчет по паспорту',
  'infoObject': 'Информация об объекте'
}
