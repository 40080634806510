import { buildTree, cleanClone } from '@/components/utils/common'
import { mixin } from './mixin/treeMixin'

export default {
  namespaced: true,
  state: {
    ...mixin().state,
    listUrl: '/handbook/category',
    detailUrl: '/handbook/category',
    name: 'handbook'
  },
  mutations: {
    ...mixin().mutations,
    updateValues (state, { values, id }) {
      const index = state.list.findIndex(item => item.id === id)
      const list = state.list[index]
      list.values = values
      state.list.splice(index, 1, list)
      state.tree = buildTree(cleanClone(state.list))
    }
  },
  actions: {
    ...mixin().actions,
    async updateValues ({ commit, dispatch }, payload) {
      commit('setLoadingState', true)
      const response = await dispatch('server/save', {
        url: '/handbook/value',
        data: { data: payload }
      }, { root: true })
      if (response) {
        commit('updateValues', response)
      }
      commit('setLoadingState', false)
      return response
    }
  },
  getters: {
    ...mixin().getters,
    getCategoryValue (state, getters) {
      return function (categoryId, valueId) {
        let value = ''
        if (valueId) {
          const category = getters['getListItemById'](categoryId)
          if (category && category.values) {
            const valueObject = category.values.find(item => item.id === valueId)
            if (valueObject) {
              value = valueObject.value
            }
          }
        }
        return value
      }
    },
    getCategoryParent (state, getters) {
      return function (categoryId) {
        let value = ''
        if (categoryId) {
          const category = getters['getListItemById'](categoryId)
          value = getters['getListItemById'](category.parent_id)
        }
        return value
      }
    },
    getCategoryName (state, getters) {
      return function (categoryId) {
        let value = ''
        if (categoryId) {
          const category = getters['getListItemById'](categoryId)
          value = category.name
        }
        return value
      }
    }
  }
}
