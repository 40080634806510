export default {
  'searchHelp': 'Поиск паспортов через автозаполнение, если паспорт найден, нажимаем на него и переходим в режим редактирования этого паспорта!',
  'marker': 'Точка',
  'polyline': 'Линия',
  'polygon': 'Полигон',
  'addMarker': 'Добавить точку',
  'addPolyline': 'Добавить точку',
  'addPolygon': 'Добавить точку',
  'cart': 'Отчеты',
  'addToCart': 'Добавить в отчеты',
  'removeFromCart': 'Удалить из отчетов',
  'moveLayer': 'Перенести слой',
  'editLayer': 'Редактировать слой',
  'setEditManually': 'Установить/изменить вручную',
  'addCoordinates': 'Добавить координаты',
  'formatCoordinates': 'Координаты вносить в системе wgs 84, формат: XX.XXXXXX, XX.XXXXXX',
  'right': 'право',
  'left': 'лево',
  'top': 'верх',
  'bottom': 'низ',
  'reportBurial': 'Справка о захоронении',
  'reportSetMonument': 'Справка об установлении надгробного памятника'
}
