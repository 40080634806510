<template>
  <div>
    <p class="mb-2">
      {{ label }}
    </p>
    <BaseSearchSelect
      :classForm="classForm"
      v-for="attribute in localRelatedAttributes"
      :key="attribute.id + '-' + attribute.value"
      :value="attribute.value"
      @input="handleInput($event)"
      :label="attribute.label"
      :show-label="false"
      :category-id="attribute.optionCategoryId"
      :error-messages="errorMessages"
      :prepend-inner-icon="prependInnerIcon"
      :disabled="disabled || Boolean(attribute.attributeRule.locked)"
      :hide-details="!errorMessages || !errorMessages.length"
      :class="classText"
    />
  </div>
</template>

<script>
import BaseSearchSelect from '@/components/base/forms/BaseSearchSelect'
import builders from '@/components/views/account/passport/detail/formBuilder/builders'
import { sortWithNull } from '@/components/utils/common'

export default {
  name: 'BuilderComplexSearchSelect',
  mixins: [builders],
  components: { BaseSearchSelect },
  props: {
    label: String,
    categoryId: Number,
    value: [Number, Array, String],
    errorMessages: Array,
    relatedAttributes: Array,
    prependInnerIcon: String,
    disabled: Boolean,
    classForm: String
  },
  data () {
    return {
      localRelatedAttributes: this.relatedAttributes
    }
  },
  computed: {
    category () {
      return this.$store.getters['handbook/getListItemById'](this.categoryId)
    }
  },
  methods: {
    handleInput (data) {
      if (this.category && this.category.values) {
        const complexValueFound = this.category.values.find(value => value.complexValue.find(complexValueItem => complexValueItem.value_id === data))
        this.processComplexValue(complexValueFound)
        this.$emit('input', complexValueFound.id)
      }
    },
    //note: подумать как передавать связанные атрибуты через модель, а не через schema - куча потенциальных багов
    processComplexValue (complexValueFound) {
      this.localRelatedAttributes.forEach((attribute) => {
        const itemByCategoryId = complexValueFound?.complexValue?.find(item => item?.category_id === attribute?.optionCategoryId)
        this.$set(attribute, 'value', itemByCategoryId ? itemByCategoryId?.value_id : null)
      })
    }
  },
  mounted () {
    if (this.value && this.category) {
      this.processComplexValue(this.category.values.find(value => value.id === this.value))
    }
  }
}
</script>

<style scoped>

</style>