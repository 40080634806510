import { processGetParams } from '@/components/utils/common'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async autocomplete ({ dispatch }, { url, params }) {
      if (params) {
        params = processGetParams(params)
      }
      const response = await dispatch('api/get', { url,  params }, { root: true })
      return response.data
    },
    async get ({ dispatch }, { url, params }) {
      if (params) {
        params = processGetParams(params)
      }
      const response = await dispatch('api/get', { url, params }, { root: true })
      return response.data
    },
    async save ({ dispatch }, { url, data }) {
      const response = await dispatch('api/post', { url, data }, { root: true })
      if (response.success) {
        await dispatch('systemMessages/success', { text: 'Данные успешно сохранены', time: 500 }, { root: true })
      }
      return response.data
    },
    async delete ({ dispatch }, { url, data }) {
      const response = await dispatch('api/delete', { url, data }, { root: true })
      if (response.success) {
        await dispatch('systemMessages/success', 'Данные успешно удалены', { root: true })
      }
      return response.data
    }
  },
  getters: {}
}
