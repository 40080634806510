export default {
  'authorization': 'Войти в личный кабинет',
  'login': 'Логин (почта)',
  'pass': 'Пароль',
  'enter': 'войти в личный кабинет',
  'forgotYourPassword': 'Забыли пароль?',
  'registration': 'зарегистрироваться',
  'fullName': 'ФИО',
  'recoveryPassword':'Восстановление пароля',
  'recovery':'Восстановить',
  'enterEmail':'Введите адрес своей электронной почты и мы отправим пароль',
  'accessKey': 'Ключ подтверждения найден, перенаправляем...',
  'noAccessKey': 'Ссылка не найдена, обратитесь к администратору системы'
}
