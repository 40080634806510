import Vue from 'vue'
import router from '@/router'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import { deleteSystemFile, readSystemFile } from '@/components/utils/mobile/filesystemInteraction'

export default {
  namespaced: true,
  state: {
    jwt: null,
    profile: null,
    currentRole: null
  },
  mutations: {
    setJwt (state, jwt) {
      if (jwt) {
        Vue.axios.defaults.headers['Authorization'] = jwt
      } else {
        delete Vue.axios.defaults.headers['Authorization']
      }
      state.jwt = jwt
    },
    setProfile (state, data) {
      state.profile = data
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && data?.role.find(item => item === 'unit')) {
        state.currentRole = 'unit'
      } else if (data?.role.find(item => item === 'admin')) {
        state.currentRole = 'admin'
      } else if (data?.role.find(item => item === 'manager')) {
        state.currentRole = 'manager'
      }
      if (!state.currentRole && !!state.profile) {
        state.currentRole = state.profile.role[0]
      }
      if (!state.profile?.role.includes(state.currentRole) && !!state.profile) {
        state.currentRole = state.profile?.role[0]
      }
    },
    setCurrentRole (state, data) {
      state.currentRole = data
    }
  },
  actions: {
    async login ({ getters, commit, dispatch }, data) {
      const response = await dispatch('api/post', { url: '/login', data }, { root: true })
      const allowInMobile = isMobileDevice() ? response?.data?.profile?.role?.includes('unit') && response.success : true
      if (response.success && allowInMobile) {
        commit('setJwt', 'Bearer ' + response.data.jwt)
        commit('setProfile', response.data.profile)
        if (window.location.pathname !== '/mobile/map') {
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            await router.push({ name: 'mobileMap' })
          } else {
            await router.push({ name: 'default' })
          }
        }
      } else if (!allowInMobile && response.success) {
        //делаю дополнительно логаут и удаление файлов, потому что мы можем остаться из-за этого на странице с картой
        if (await readSystemFile('profile/l')) {
          await deleteSystemFile('profile/p')
          await deleteSystemFile('profile/l')
        }
        dispatch('systemMessages/error', {
          text: 'У вас нет подходящей роли: юнит. Обратитесь в поддержку.',
          timeout: 8000
        }, { root: true })
        await dispatch('auth/logout', {}, { root: true })
      }
      return response.data
    },
    async logout ({ commit, dispatch }) {
      const response = await dispatch('api/get', { url: '/logout' }, { root: true })
      if (response.success) {
        commit('setJwt', null)
        commit('setProfile', null)
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          router.app?._route?.name !== 'mobileLogin' && await router.push({ name: 'mobileLogin' })
        } else {
          router.app?._route?.name !== 'login' && await router.push({ name: 'login' })
        }
      }
      return response.data
    },
    async changePassword ({ dispatch }, { data }) {
      const response = await dispatch('api/post', { url: '/user/change-password', data: { data } }, { root: true })
      if (response.success) {
        dispatch('systemMessages/success', 'Пароль успешно изменен', { root: true })
      }
      return response.data
    },
    async isUnique ({ dispatch }, { data }) {
      let response = await dispatch('api/post', { url: '/user-identity/check-unique', data: { data } }, { root: true })
      return response.data
    },
    async saveProfile ({ commit, dispatch }, { data }) {
      const response = await dispatch('api/post', { url: '/user/profile', data: { data } }, { root: true })
      if (response.success) {
        commit('setProfile', response.data)
        dispatch('systemMessages/success', 'Данные успешно сохранены', { root: true })
      }
      return response.data
    },
    async signUp ({ dispatch }, data) {
      const response = await dispatch('api/post', { url: '/sign-up', data }, { root: true })
      return response.data
    },
    async fillProfile ({ getters, commit, dispatch }) {
      let response = getters['getProfile']
      if (!response) {
        response = await dispatch('api/get', { url: '/user/profile' }, { root: true })
        if (response.success) {
          commit('setProfile', response.data)
        }
      }
      return response
    },
    async resetPassword ({ dispatch }, email) {
      const response = await dispatch('api/post', { url: '/reset-password', data: { data: { email } } }, { root: true })
      if (response.success) {
        dispatch('systemMessages/success', 'Пароль успешно сброшен', { root: true })
      }
      return response.data
    }
  },
  getters: {
    getJwt: state => state.jwt,
    getName: state => state.profile ? state.profile.username : state.profile,
    getCurrentRole: state => {
      let currentRole = null
      if (state.currentRole) {
        currentRole = state.currentRole
      } else if (state.profile) {
        currentRole = state.profile.role[0]
      }
      return currentRole
    },
    getProfile: state => state.profile,
    //проверяем доступ для роли, если требуется указываем специфическое разрешение
    can: (state, getters) => (role = null, name = null) => {
      let currentRole = getters['getCurrentRole']
      let result = false
      if (state.profile) {
        if (currentRole === 'admin') {
          result = true
        } else if (role && currentRole === role) {
          result = true
        } else if (name && state.profile.permissions[currentRole].some(permission => permission.name === name)) {
          result = true
        }
      }

      return result
    }
  }
}
