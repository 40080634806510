import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from '@/store'
import Qs from 'qs'
import { Config } from '@/config'

const jwt = store.getters['auth/getJwt']

const axiosInstance = axios.create({
  baseURL: Config.baseUrl,
  timeout: Config.baseTimeout,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

if (jwt) {
  axiosInstance.defaults.headers['Authorization'] = jwt
}

// конфиг для адекватной сериализации объекта в GET-параметры
axiosInstance.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    })
  }

  return config
})

Vue.use(VueAxios, axiosInstance)

export default new VueAxios()
