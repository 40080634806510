import Point from '@/components/structures/geometry/point'

class LineString {
  constructor (coordinatesString = null) {
    this.type = 'LineString'
    this.coordinates = [[]]
    if (coordinatesString) {
      if (Array.isArray(coordinatesString)) {
        this.coordinates = coordinatesString
      } else {
        this.coordinates = coordinatesString.split('\n').map(point => (new Point(point)).coordinates)
      }
    }
  }

  validate () {
    let valid = true
    if (this.coordinates.length < 2 || this.coordinates.some(point => !(new Point(point)).validate())) {
      valid = false
    }
    return valid
  }

  toString () {
    return this.coordinates.map(point => (new Point(point).toString())).join('\n')
  }
}

export default LineString
