export default {
  'id': '#',
  'enter': 'Вход',
  'exit': 'Выход',
  'search': 'Поиск',
  'create': 'Создать',
  'save': 'Сохранить',
  'cancel': 'Отменить',
  'cancelLast': 'Отменить последнее действие',
  'delete': 'Удалить',
  'dataBaseDelete': 'Удалить из базы',
  'restore': 'Восстановить',
  'close': 'Закрыть',
  'add': 'Добавить',
  'list': 'Список',
  'layers': 'Слои',
  'addList': 'Добавить списком',
  'export': 'Экспорт',
  'import': 'Импорт',
  'images': 'Изображения',
  'docs': 'Документы',
  'groupAttributes': 'Группа атрибутов',
  'historyLog': 'История изменений',
  'filter': 'Фильтр',
  'record': 'Отчет',
  'yes': 'Да',
  'no': 'Нет',
  'ok': 'Ок',
  'choose': 'Выбрать',
  'chooseFile': 'Выбрать файл',
  'done': 'Готово',
  'updated_at': 'Дата последнего редактирования',
  'created_at': 'Дата создания',
  'edit': 'Редактировать',
  'info': 'Информация',
  'menu': 'Меню',
  'string': 'строка',
  'number': 'число',
  'confirm': 'Подтвердить',
  'sendConfirm': 'Отправить на подтверждение',
  'actions': 'Действия',
  'value': 'Значение',
  'attention': 'Внимание',
  'createAttr': 'Создать атрибут',
  'createGroup': 'Создать группу',
  'download': 'Загрузить',
  'createReport': 'Создать Отчет',
  'reportWithSettings': 'Отчет с настройками',
  'coordinates': 'Координаты',
  'geometry': 'Геометрия',
  'clearSelected': 'Очистить выбранное'
}
