export default [
  {
    menuItem: 'passportDashboard',
    role: null
  },
  {
    menuItem: 'passportDetail',
    role: null
  },
  {
    menuItem: 'images',
    role: null
  },
  {
    menuItem: 'passportDocs',
    role: null
  },
  {
    menuItem: 'passportMap',
    role: null
  },
  {
    menuItem: 'passportLayers',
    role: null
  },
  {
    menuItem: 'passportReports',
    role: null
  },
  /*{
     menuItem: 'passportMedia',
     role: null
   }*/
]
