export default {
  'search': 'Поиск субъекта',
  'caseSensitive': 'учитывать регистр',
  'create': 'Создать субъект',
  'upload': 'Добавить списком',
  'nameRegion':'Наименование субъекта',
  'enterRegion':'Введите наименование',
  'enterPolygon':'Введите координаты полигона',
  'templateDoc' : 'Шаблоны докуметов',
  'listRegion' : 'Список субъектов',
  'is_leaf': 'Содержит паспорта',
  'subject': 'Субъект РФ',
  'locality': 'Населенный пункт'
}
