import moment from 'moment/moment'

export default {
  computed: {
    controllerName () {
      return this.controller ? this.controller : this.$route.name.split(/[A-Z]/g)[0]
    },
    currentDate() {
      return moment().format('DD.MM.YYYY')
    }
  },
  methods: {
    formatValue (categoryChain, valueId) {
      return this.$store.getters['serviceData/optionValue'](categoryChain, valueId)
    },
    timestampToDatetime (timestamp) {
      let date = new Date(timestamp * 1000)
      return date.toLocaleString('ru-RU')
    },
    formatTodayDate (timestamp) {
      let rez = new Date(timestamp * 1000).toLocaleString('ru-RU')
      if (timestamp !== null) {
        const createAt = new Date(timestamp * 1000).toLocaleDateString('ru-RU')
        if (createAt === new Date().toLocaleDateString('ru-RU')) {
          rez = 'Сегодня в ' + new Date(timestamp * 1000).toLocaleTimeString('ru-RU')
        }
      } else {
        rez = 'Недавно'
      }
      return rez
    },
    timestampToDate (timestamp) {
      let date = new Date(timestamp * 1000)
      return date.toLocaleDateString('ru-RU')
    },
    dateToTimestamp (date) {
      return Date.parse(date) / 1000
    },
    maskValue (value, pattern) {
      if (value) {
        let i = 1
        let v = value.toString()
        return pattern.replace(/#/g, _ => v[i++])
      }
    },
    formatPrice (price) {
      let formatter = new Intl.NumberFormat('ru')
      return formatter.format(price)
    },
    reformatPrice (price) {
      return parseInt(price.replace(/\xA0/g, ''))
    },
    bytesToSize (bytes, seperator = '') {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes}${seperator}${sizes[i]}`
      return `${(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`
    },
    clearUnderscore (attribute) {
      return attribute.split('_').join(' ')
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text && text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    }
  }
}
