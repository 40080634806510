const timeout = 10000

export default {
  namespaced: true,
  actions: {
    async create ({ dispatch }, { data }) {
      return dispatch('api/post', { url: 'report/create', data: { data } }, { root: true })
    },
    async check ({ dispatch }, { data }) {
      return dispatch('api/post', { url: 'report/check', data: { data } }, { root: true })
    },
    async result ({ dispatch }, { data }) {
      return dispatch('api/post', { url: 'report/result', data: { data } }, { root: true })
    }
  }
}
