<template>
  <v-container>
    <v-row>
      <v-textarea
        v-model="coordinates"
        :label="label"
        :disabled="disabled"
        outlined
        dense
        :error-messages="errorMessages"
        :prepend-inner-icon="prependInnerIcon"
      >
      </v-textarea>
      <BaseHint
        style="z-index: 999999999"
        :text="'Координаты каждой новой точки необходимо вводить с новой строки, пример:\n' +
      '\u203347.78967,39.54785\n' +
      '47.11113,39.25835 \n' +
       '47.18523,39.25895\u2033'"
      />
    </v-row>
  </v-container>
</template>

<script>
import GeometryFactory from '@/components/structures/geometryFactory'
import BaseHint from '@/components/base/UI/BaseHint'

export default {
  name: 'BaseCoordinates',
  components: { BaseHint },
  props: {
    value: Object,
    disabled: Boolean,
    errorMessages: Array,
    label: String,
    prependInnerIcon: String
  },
  computed: {
    coordinates: {
      get () {
        let geometry = new GeometryFactory(this.value)
        return geometry.toString()
      },
      set (val) {
        let geometry = new GeometryFactory({type: this.value.type, coordinates: val})
        this.$emit('input', geometry)
      }
    }
  }
}
</script>

<style scoped>

</style>
