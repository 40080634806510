<template>
  <BaseRange
    :min="min"
    :max="max"
    :label="label"
    @input="handleInput"
    :name="name"
    :disabled="disabled"
    :eav-entity-id="eavEntityId"
    :value="value"
  />
</template>

<script>
import BaseRange from '@/components/base/forms/BaseRange'

export default {
  name: 'BuilderRange',
  components: { BaseRange },
  props: {
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    label: String,
    name: String,
    disabled: Boolean,
    eavEntityId: Number,
    value: Array
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value)
    }
  },
}
</script>