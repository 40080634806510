export default {
  'organization': 'Организация',
  'user': 'Пользователь',
  'passport': 'Паспорт',
  'status': 'Статус верификации',
  'created_at': 'Дата создания',
  'updated_at': 'Дата изменения',
  'name': 'Имя',
  'mail': 'Почта',
  'phone': 'Телефон',
  'title': 'Название',
  'location': 'Местоположение',
  'price': 'Цена',
  'note': 'Примечание',
  'cadastr_number': 'Кадастровый номер',
  'send': 'Верифицировать',
  'verifying': 'Верифицируется...'
}
