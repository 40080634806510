import { readSystemFile } from '@/components/utils/mobile/filesystemInteraction'

export default {
  namespaced: true,
  state: {
    messages: []
  },
  mutations: {
    addMessage (state, text) {
      state.messages.push(text)
    },
    hideMessage (state, index) {
      state.messages.splice(index, 1)
    }
  },
  actions: {
    success ({ commit }, payload) {
      commit('addMessage', {
        text: payload?.text || payload,
        type: 'success',
        timeout: payload?.time || 2000,
        icon: 'mdi-cloud-check'
      })
    },
    error ({ commit }, payload) {
      commit('addMessage', {
        text: payload?.text || payload,
        type: 'error',
        timeout: payload?.time || 4000,
        icon: 'mdi-alert-remove'
      })
    },
    info ({ commit }, payload) {
      commit('addMessage', {
        text: payload?.text || payload,
        type: 'info',
        timeout: payload?.time || 7000,
        icon: 'mdi-cloud-alert'
      })
    },
    hideMessage ({ commit }, text) {
      commit('hideMessage', text)
    },
    async handleError ({ dispatch }, e) {
      try {
        if (e && e.response && e.response.status === 401) {
          await dispatch('auth/logout', {}, { root: true })
          if(JSON.parse(await readSystemFile('profile/l'))) {
            location.reload() //перезагружаю страницу, чтобы произошел автологин
          }
        } else {
          await dispatch('systemMessages/error', `Не удалось загрузить данные, повторите попытку позже`, { root: true })
        }
      } catch (error) {
      }
      return true
    }
  },
  getters: {
    getMessages (state) {
      return state.messages
    }
  }
}
