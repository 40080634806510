export default {
  computed: {
    classText () {
      let classText = ''
      if (!this.isTransformAppendIcon) {
        classText = 'noTransformAppendIcon '
      }
      if (this.classForm) {
        classText += this.classForm
      }
      if (!this.errorMessages || !this.errorMessages.length) {
        classText += ' mb-3'
      }
      return classText
    }
  }
}