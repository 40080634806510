import Vue from 'vue'
//import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/lib/locale/ru'
import '@mdi/font/css/materialdesignicons.css'
import 'typeface-roboto/index.css'

import Vuetify, { VCheckbox, VCol, VRow, VSelect, VTextField, VTooltip } from 'vuetify/lib'
import { ClickOutside, Intersect, Resize, Ripple, Touch } from 'vuetify/lib/directives'
import BuilderSelect from '@/components/views/account/passport/detail/formBuilder/BuilderSelect'
import BuilderGeometry from '@/components/views/account/passport/detail/formBuilder/BuilderGeometry'
import BuilderVerification from '@/components/views/account/passport/detail/formBuilder/BuilderConfirm'
import BuilderRange from '@/components/base/forms/BuilderRange'
import BaseSearchSelect from '@/components/base/forms/BaseSearchSelect'
import BuilderSearchSelect from '@/components/base/forms/BuilderSearchSelect'
import BuilderText from '@/components/base/forms/BuilderText'
import BuilderComplexSearchSelect from '@/components/base/forms/BuilderComplexSearchSelect'
import BuilderMultipleSearchSelect from '@/components/base/forms/BuilderMultipleSearchSelect'
import BuilderTextField from '@/components/views/account/passport/detail/formBuilder/BuilderTextField'
import BaseDatePicker from '@/components/base/BaseDatePicker'


Vue.use(Vuetify, {
  components: {
    VRow,
    VTooltip,
    VCol,
    VTextField: BuilderTextField,
    VCheckbox,
    VSelect: BuilderSelect,
    VOption: BuilderSelect,
    VMultipleOption: BuilderMultipleSearchSelect,
    VComplexOption: BuilderComplexSearchSelect,
    VRangeOption: BuilderRange,
    VGeometry: BuilderGeometry,
    VConfirm: BuilderVerification,
    VSearchSelect: BaseSearchSelect,
    VComplexSearchSelect: BuilderSearchSelect,
    VAttributeTextField: BuilderText,
    VDatePicker: BaseDatePicker
  },
  directives: { Ripple, Intersect, Touch, Resize, ClickOutside }
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#009EDB',
        secondary: '#56C02B',
        accent: '#EBF3FA',
        error: '#E44747',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        background: '#253E56'
      }
    }
  },
  lang: {
    locales: { ru },
    current: 'ru'
  },
  icons: {
    iconfont: 'mdi'
  }
})
