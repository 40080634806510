export default {
  'title': 'Обращения граждан',
  'full_name': 'ФИО заявителя',
  'phone': 'Телефон',
  'email': 'E-mail',
  'message': 'Текст обращения',
  'request_attachment': 'Текст обращения',
  'passport_id': 'Паспорт озеленения',
  'response_date': 'Дедлайн) Дата подготовки ответа (14 дней с даты занесения в систему)',
  'response_attachment': 'Ответ на обращение',
  'status': 'Статус - Меняется в зависимости от таймера',
  'appeal_date': 'Дата обращения'
}
