export default {
  namespaced: true,
  state: {
    currentDownloading: null, // id загружаемого паспорта
    listDownloading: [], // список id для очереди загрузки
    currentSync: null, //та же логика для синхронизации
    listSync: [],
    currentLoadImage: null,
    tileProcess: {
      count: 0,
      process: 0
    }
  },
  mutations: {
    finishDownload (state) {
      state.listDownloading.splice(0, 1)
      if (state.listDownloading.length) {
        state.currentDownloading = state.listDownloading[0]
      } else {
        state.currentDownloading = null
      }
    },
    finishSync (state) {
      state.listSync.splice(0, 1)
      if (state.listSync.length) {
        state.currentSync = state.listSync[0]
      } else {
        state.currentSync = null
      }
    },
    changeTileProcess (state, { count, current }) {
      if (current) {
        state.tileProcess.process += current
      }
      if (count) {
        state.tileProcess.count += count
      }
    },
    finishTile (state) {
      state.tileProcess.process = 0
      state.tileProcess.count = 0
    },
    addInQueue (state, { type, id }) { // type - Sync или Downloading, id - passport_id
      //list + type = listDownloading || listSync; current + type = currentSync || currentDownloading
      state['list' + type].push(id)
      if (!state['current' + type]) {
        state['current' + type] = state['list' + type][0]
      }
    },
    setLoadImage (state, url) {
      state.currentLoadImage = url
    }
  },
  actions: {},
  getters: {
    currentDownloading: state => state.currentDownloading,
    currentSync: state => state.currentSync,
    currentLoadImage: state => state.currentLoadImage,
    currentTiles: state => state.tileProcess
  }
}
