import LineString from '@/components/structures/geometry/lineString'

class Polygon {
  constructor (coordinatesString = null) {
    this.type = 'Polygon'
    this.coordinates = [[[]]]
    if (coordinatesString) {
      if (Array.isArray(coordinatesString)) {
        this.coordinates = coordinatesString
      } else {
        this.coordinates = coordinatesString.split('\n\n').map(line => (new LineString(line)).coordinates)
      }
    }
  }

  validate () {
    let valid = true
    if (!this.coordinates.length || this.coordinates.some(line => line.length < 3 || !(new LineString(line).validate()))) {
      valid = false
    }
    return valid
  }

  toString () {
    return this.coordinates.map(line => (new LineString(line).toString())).join('\n\n')
  }
}

export default Polygon
