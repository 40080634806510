import { decimal } from 'vuelidate/lib/validators'

class Point {
  constructor (coordinatesString = null) {
    this.type = 'Point'
    this.coordinates = []
    if (coordinatesString) {
      if (Array.isArray(coordinatesString)) {
        this.coordinates = coordinatesString
      } else {
        this.coordinates = coordinatesString.split(',').map(coordinate => !isNaN(coordinate) && coordinate ? parseFloat(coordinate) : '')
      }
    }
  }

  validate () {
    let valid = true
    if (this.coordinates.length !== 2 || !this.coordinates.every(item => decimal(item))) {
      valid = false
    }
    return valid
  }

  toString () {
    return this.coordinates.join(',')
  }
}

export default Point
