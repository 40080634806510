<template>
  <v-select
    v-model="input"
    :items="items"
    item-text="value"
    item-value="id"
    :label="label"
    :error-messages="errorMessages"
    :prepend-inner-icon="prependInnerIcon"
    dense
    :disabled="disabled"
    outlined
    :hide-details="!errorMessages || !errorMessages.length"
  ></v-select>
</template>

<script>
export default {
  name: 'BuilderSelect',
  props: {
    label: String,
    categoryId: Number,
    value: Number,
    errorMessages: Array,
    prependInnerIcon: String,
    disabled: Boolean
  },
  computed: {
    input: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    items () {
      const category = this.$store.getters['handbook/getListItemById'](this.categoryId)
      return category && category.values ? category.values : []
    }
  }
}
</script>

<style scoped>

</style>
