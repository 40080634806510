import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async put ({ commit, dispatch }, { url, data }) {
      let response = {
        success: true,
        data: null
      }
      try {
        const serverResponse = await Vue.axios.put(url + '?currentRole=' + this.state.auth.currentRole, data)
        if (serverResponse.data.success) {
          response.data = serverResponse.data.data
        } else {
          await dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
          response.success = false
        }
      } catch (e) {
        await dispatch('systemMessages/handleError', e, { root: true })
        response.success = false
      }
      return response
    },
    async post ({ commit, dispatch }, { url, data }) {
      let response = {
        success: true,
        data: null
      }
      try {
        const serverResponse = await Vue.axios.post(url + '?currentRole=' + this.state.auth.currentRole, data)
        if (serverResponse.data.success) {
          response.data = serverResponse.data.data
        } else {
          await dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
          response.success = false
        }
      } catch (e) {
        await dispatch('systemMessages/handleError', e, { root: true })
        response.success = false
      }
      return response
    },
    async get ({ commit, dispatch }, { url, params }) {
      let response = {
        success: true,
        data: null
      }
      try {
        const serverResponse = await Vue.axios.get(url, {
          params: {
            ...params,
            currentRole: this.state.auth.currentRole
          }
        })
        if (serverResponse.data.success) {
          response.data = serverResponse.data.data
        } else {
          await dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
          response.success = false
        }
      } catch (e) {
        await dispatch('systemMessages/handleError', e, { root: true })
        response.success = false
      }
      return response
    },
    async delete ({ dispatch }, { url, data }) {
      let response = {
        success: true,
        data: null
      }
      try {
        const serverResponse = await Vue.axios.delete(url, {
          params: {
            ...data,
            currentRole: this.state.auth.currentRole
          }
        })
        if (serverResponse.data.success) {
          response.data = serverResponse.data.data
        } else {
          await dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
          response.success = false
        }
      } catch (e) {
        await dispatch('systemMessages/handleError', e, { root: true })
        response.success = false
      }
      return response
    }
  },
  getters: {}
}
