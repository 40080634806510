export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async changeStatusVerification ({ dispatch }, data) {
      const response = await dispatch('api/post', { url: '/verification/detail', data }, { root: true })
      return response.data
    }
  },
  getters: {}
}
