export default [
  //note: стартовая страница
  {
    path: '/account',
    name: 'default',
    redirect: {
      name: 'map'
    }
  },
 {
    path: '/account/dashboard',
    name: 'dashboard',
    meta: {
      title: 'Дашборд',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-view-dashboard'
    },
    component: () => import('../views/account/Dashboard.vue')
  },
  {
    path: '/account/help',
    name: 'help',
    meta: {
      title: 'Помощь',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-help-circle'
    },
    component: () => import('../views/account/Help.vue')
  },
  {
    path: '/account/region',
    name: 'region',
    meta: {
      title: 'Регион/Город',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-earth-box'
    },
    component: () => import('@/components/views/account/region/Detail.vue')
  },
  {
    path: '/account/report',
    name: 'report',
    meta: {
      title: 'Отчеты',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-text-box-multiple-outline'
    },
    component: () => import('../views/account/Report.vue')
  },
  {
    path: '/account/organization',
    name: 'organization',
    meta: {
      title: 'Компании',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-domain'
    },
    component: () => import('@/components/views/account/organization/List.vue')
  },
  {
    path: '/account/appeal',
    name: 'appeal',
    meta: {
      title: 'Обращения граждан',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-forum'
    },
    component: () => import('@/components/views/account/appeal/List.vue')
  },
  {
    path: '/account/appeal/detail/:id?',
    name: 'appealDetail',
    meta: {
      title: 'Обращения граждан',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('@/components/views/account/appeal/Detail.vue')
  },
  {
    path: '/account/request-felling-ticket',
    name: 'requestFellingTicket',
    meta: {
      title: 'Заявление на удаление',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-file-document-edit-outline'
    },
    component: () => import('@/components/views/account/requestFellingTicket/List')
  },
  {
    path: '/account/request-felling-ticket/detail/:id?',
    name: 'requestFellingTicketDetail',
    meta: {
      title: 'Заявление на удаление',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('@/components/views/account/requestFellingTicket/Detail')
  },
  {
    path: '/account/felling-ticket',
    name: 'fellingTicket',
    meta: {
      title: 'Порубочные билеты',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-text-box-check'
    },
    component: () => import('@/components/views/account/fellingTicket/List')
  },
  {
    path: '/account/felling-ticket/detail/:id?',
    name: 'fellingTicketDetail',
    meta: {
      title: 'Порубочные билеты',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('@/components/views/account/fellingTicket/Detail')
  },
  {
    path: '/account/organization/detail/:id',
    redirect: { name: 'organizationDetail' },
    name: 'organizationMain',
    meta: {
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('@/components/views/account/organization/Detail.vue'),
    children: [
      {
        path: '',
        name: 'organizationDetail',
        meta: {
          title: 'Общая информация компании',
          layout: 'AccountLayout',
          icon: 'mdi-view-dashboard',
          auth: true
        },
        component: () => import('@/components/views/account/organization/detail/views/OrganizationDetail')
      },
      {
        path: 'user/:id',
        name: 'organizationUsers',
        meta: {
          title: 'Пользователи',
          layout: 'AccountLayout',
          icon: 'mdi-account-multiple',
          auth: true
        },
        component: () => import('@/components/views/account/user/List.vue')
      },
      {
        path: 'user/:id/detail/:userId',
        name: 'organizationUserDetail',
        meta: {
          title: 'Пользователи',
          layout: 'AccountLayout',
          auth: true
        },
        component: () => import('@/components/views/account/user/Detail.vue')
      },
      {
        path: 'subscription/:id',
        name: 'organizationSubscriptions',
        meta: {
          title: 'Подписки',
          layout: 'AccountLayout',
          icon: 'mdi-handshake-outline',
          auth: true
        },
        component: () => import('@/components/views/account/subscription/List.vue')
      },
      {
        path: 'subscription/:id/detail/:subscriptionId',
        name: 'organizationSubscriptionDetail',
        meta: {
          title: 'Подписки',
          layout: 'AccountLayout',
          auth: true
        },
        component: () => import('@/components/views/account/subscription/Detail.vue')
      },
      {
        path: 'constructor/:objectId',
        name: 'organizationConstructorConfig',
        meta: {
          title: 'Конфигурация объекта',
          layout: 'AccountLayout',
          auth: true
        },
        component: () => import('@/components/views/account/organization/detail/views/ConfigObjectDetail.vue')
      }
    ]
  },
  {
    path: '/account/user',
    name: 'user',
    meta: {
      title: 'Пользователи',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-account-multiple'
    },
    component: () => import('@/components/views/account/user/List.vue')
  },
  {
    path: '/account/user/detail/:id?',
    name: 'userDetail',
    meta: {
      title: 'Пользователи',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('@/components/views/account/user/Detail.vue')
  },
  {
    path: '/account/subscription',
    name: 'subscription',
    meta: {
      title: 'Подписки',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-account-multiple-check'
    },
    component: () => import('@/components/views/account/subscription/List.vue')
  },
  {
    path: '/account/subscription/detail/:id?',
    name: 'subscriptionDetail',
    meta: {
      title: 'Подписки',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('@/components/views/account/subscription/Detail.vue')
  },
  {
    path: '/account/profile',
    name: 'profile',
    meta: {
      title: 'Мой профиль',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-account'
    },
    component: () => import('../components/views/account/profile/Detail.vue')
  },
  {
    path: '/account/passport',
    name: 'passport',
    meta: {
      title: 'Реестр паспортов территорий',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-folder'
    },
    component: () => import('@/components/views/account/passport/List.vue')
  },
  {
    path: '/account/passport/detail/:id',
    redirect: { name: 'commonInfo' },
    name: 'passportMain',
    meta: {
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('@/components/views/account/passport/Detail.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'passportDashboard',
        meta: {
          title: 'Общая информация',
          layout: 'AccountLayout',
          icon: 'mdi-view-dashboard',
          auth: true
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportDashboard.vue'),
        children: [
          {
            path: '',
            name: 'commonInfo',
            meta: {
              title: 'Объекты паспорта',
              layout: 'AccountLayout',
              auth: true
            },
            component: () => import('@/components/views/account/passport/detail/views/passportDashboard/CommonInfo')
          },
          {
            path: 'entity/:entityId',
            name: 'passportObjectList',
            meta: {
              title: 'Объекты паспорта',
              layout: 'AccountLayout',
              auth: true
            },
            component: () => import('@/components/views/account/passport/detail/views/passportDashboard/PassportObjectsTable')
          },
          {
            path: 'entity/:entityId/detail/:objectId',
            name: 'passportObjectDetail',
            meta: {
              title: 'Объекты паспорта',
              layout: 'AccountLayout',
              auth: true
            },
            component: () => import('@/components/views/account/passport/detail/views/passportDashboard/PassportObjectDetail.vue')
          },
          {
            path: 'images',
            name: 'images',
            meta: {
              title: 'Фотографии',
              layout: 'AccountLayout',
              auth: true,
              icon: 'mdi-image'
            },
            component: () => import('@/components/views/account/passport/detail/views/PassportPhotos.vue')
          }
        ]
      },
      {
        path: 'detail',
        name: 'passportDetail',
        meta: {
          title: 'Реквизиты паспорта',
          layout: 'AccountLayout',
          icon: 'mdi-folder-information',
          auth: true
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportDetail.vue')
      },
      {
        path: 'export',
        name: 'passportExport',
        meta: {
          title: 'Экспорт',
          layout: 'AccountLayout',
          auth: true
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportExport.vue')
      },
      {
        path: 'reports',
        name: 'passportReports',
        meta: {
          title: 'Отчеты',
          layout: 'AccountLayout',
          auth: true,
          icon: 'mdi-file-multiple'
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportReports.vue')
      },
      {
        path: 'map',
        name: 'passportMap',
        meta: {
          title: 'Карта',
          layout: 'AccountLayout',
          auth: true,
          icon: 'mdi-map'
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportMap/PassportMap.vue')
      },
      {
        path: 'confirm',
        name: 'passportConfirm',
        meta: {
          title: 'Верификация данных',
          layout: 'AccountLayout',
          auth: true
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportConfirm.vue')
      },
      {
        path: 'files',
        name: 'passportDocs',
        meta: {
          title: 'Документы',
          layout: 'AccountLayout',
          auth: true,
          icon: 'mdi-file-document-multiple-outline'
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportDocs.vue')
      },
      {
        path: 'media',
        name: 'passportMedia',
        meta: {
          title: 'Медиа файлы',
          layout: 'AccountLayout',
          auth: true,
          icon: 'mdi-lock',
          opacity: 0.5
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportMedia.vue')
      },
      {
        path: 'layers',
        name: 'passportLayers',
        meta: {
          title: 'Слои',
          layout: 'AccountLayout',
          auth: true,
          icon: 'mdi-layers-triple-outline'
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportLayers.vue')
      },
      {
        path: 'verification',
        name: 'passportVerification',
        meta: {
          title: 'Верификация',
          layout: 'AccountLayout',
          auth: true,
          icon: 'mdi-handshake-outline'
        },
        component: () => import('@/components/views/account/passport/detail/views/PassportVerification.vue')
      }
    ]
  },
  {
    path: '/account/handbook',
    name: 'handbook',
    meta: {
      title: 'Справочники',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-bookshelf'
    },
    component: () => import('@/components/views/account/handbook/Detail')
  },
  //todo: подумать как динамически подгружать компоненты исходя из роутов
  {
    path: '/account/constructor',
    name: 'constructor',
    meta: {
      title: 'Конструктор',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-folder-cog'
    },
    component: () => import('@/components/views/account/constructor/List.vue')
  },
  {
    path: '/account/constructor/detail/:id?',
    name: 'constructorDetail',
    meta: {
      title: 'Конструктор',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('@/components/views/account/constructor/Detail.vue')
  },
  {
    path: '/account/map',
    name: 'map',
    meta: {
      title: 'Карта',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-map'
    },
    component: () => import('../components/views/account/map/Detail.vue')
  },
  {
    path: '/account/verification',
    name: 'verification',
    meta: {
      title: 'Верификация',
      layout: 'AccountLayout',
      auth: true,
      icon: 'mdi-checkbox-marked-circle',
      withNotificationIcon: true
    },
    component: () => import('@/components/views/account/verification/List.vue')
  },
  {
    path: '/account/verification/detail/:id',
    redirect: { name: 'InfoAboutCommit' },
    name: 'verificationDetail',
    meta: {
      title: 'Верификация',
      layout: 'AccountLayout',
      auth: true
    },
    component: () => import('@/components/views/account/verification/Detail.vue'),
    children: [
      {
        path: '',
        name: 'InfoAboutCommit',
        meta: {
          title: 'Общая информация',
          layout: 'AccountLayout',
          icon: 'mdi-information-outline',
          auth: true
        },
        component: () => import('@/components/views/account/verification/detail/views/verificationDashboard/InfoAboutCommit.vue')
      },
      {
        path: 'commit/:commitId',
        name: 'commitObject',
        meta: {
          title: 'Список изменений',
          layout: 'AccountLayout',
          auth: true
        },
        component: () => import('@/components/views/account/verification/detail/DetailView.vue')
      }
    ]
  }
]
