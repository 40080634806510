import Vue from 'vue'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    modelStatic: null,
    info: {
      type: 'passport',
      layer: null,
      detail: null,
      schema: null,
      schemas: null,
      isLoading: false,
      filter: {}
    },
    view: {
      zoom: 12,
      center: [47.212619, 39.731147]
    },
    cart: null,
    isCollapseMapInfo: false,
    isRulerActive: false,
    deleteObjectsTable: [], // переменная для удаления объекта через таблицы из карты
    blockedObjects: {}, //заблокированные слои
    showObjects: {}, // показанные слои
    filterObjects: {}, //фильтр слоёв объектов на карте
    isCreateMode: false,
    lastSelected: null, // последний выбранный объект
  },
  mutations: {
    modelStatic (state, modelStatic) {
      state.modelStatic = modelStatic
    },
    changeCreateMode (state, status) {
      state.isCreateMode = status
    },
    lastSelected(state, layer) {
      state.lastSelected = layer
    },
    info (state, info) {
      state.info = info
    },
    deleteObjectsTable (state, deleteObjectTable) {
      state.deleteObjectsTable.push(deleteObjectTable)
    },
    blockedObjects (state, { id, status }) {
      state.blockedObjects = { ...state.blockedObjects, [id]: status }
    },
    showObjects (state, { id, status }) {
      state.showObjects = { ...state.showObjects, [id]: status }
    },
    clearSomeObject (state, objectName) {
      state[objectName] = {}
    },
    changeFilterObjects (state, { id, status }) {
      state.filterObjects = { ...state.filterObjects, [id]: status }
    },
    zoom (state, data) {
      state.view.zoom = data
    },
    center (state, data) {
      state.view.center = data
    },
    initCart (state, schemas) {
      if (!state.cart) {
        Vue.set(state, 'cart', {})
      }
      //удаляем из корзины отсутствующие элементы schemas
      state.cart = _.pick(state.cart, schemas.map(schema => String(schema)))
      //добавляем новые элементы schemas
      schemas.forEach(schema => {
        if (!state.cart[schema]) {
          Vue.set(state.cart, schema, [])
        }
      })
    },
    clearCart (state, schemaId = null) {
      if (schemaId === null) {
        for (let cartSchemaId in state.cart) {
          state.cart[cartSchemaId] = []
        }
      } else {
        state.cart[schemaId] = []
      }
    },
    addCartItem (state, { schemaId, item }) {
      state.cart[schemaId].push(item)
    },
    removeCartItem (state, { schemaId, item }) {
      const itemIndex = state.cart[schemaId].findIndex(cartItem => cartItem.properties.id === item.properties.id || cartItem.properties.element_number === item.properties.element_number)
      if (itemIndex === -1) {
        console.log('Невозможно найти элемент: ', item)
      } else {
        state.cart[schemaId].splice(itemIndex, 1)
      }
    },
    destroyCart (state) {
      state.cart = null
    },
    changeCollapse (state, value = null) {
      if (value !== null) {
        state.isCollapseMapInfo = value
      } else {
        state.isCollapseMapInfo = !state.isCollapseMapInfo
      }
    },
    rulerChange (state, value = null) {
      state.isRulerActive = value.sttus
    }
  },
  actions: {},
  getters: {
    modelStatic: state => state.modelStatic,
    info: state => state.info,
    zoom: state => state.view.zoom,
    center: state => state.view.center,
    cart: state => state.cart,
    inCart: state => (schemaId, item) => state.cart[schemaId].findIndex(cartItem => cartItem.properties.id === item.properties.id || cartItem.properties.element_number === item.properties.element_number) > -1,
    cartCount: state => state.cart, //todo: дописать
    isCollapseMapInfo: state => state.isCollapseMapInfo,
    isRulerActive: state => state.isRulerActive,
    deleteObjectsTable: state => state.deleteObjectsTable,
    blockedObjects: state => state.blockedObjects,
    showObjects: state => state.showObjects,
    filterObjects: state => state.filterObjects,
    isCreateMode: state => state.isCreateMode,
    lastSelected: state => state.lastSelected,
  }
}
