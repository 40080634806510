export default [
  {
    menuItem: 'organizationDetail',
    role: null
  },
  {
    menuItem: 'organizationUsers',
    role: null
  },
  {
    menuItem: 'organizationSubscriptions',
    role: null
  }
]