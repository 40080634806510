<template>
  <div>
    <BaseSearchSelect
      v-for="item in relatedAttributes"
      v-if="item.is_filter"
      :key="item.id"
      :label="item.label"
      :name="name"
      :isTransformAppendIcon="isTransformAppendIcon"
      itemValue="id"
      itemText="value"
      :category-id="item.optionCategoryId"
      @input="handleInput"
      :disabled="disabled"
      :classForm="classForm"
    />
  </div>
</template>

<script>
import BaseSearchSelect from '@/components/base/forms/BaseSearchSelect'
//for complex option
export default {
  name: 'BuilderSelect',
  components: { BaseSearchSelect },
  props: {
    relatedAttributes: Array,
    label: String,
    isTransformAppendIcon: Boolean,
    categoryId: Number,
    name: String,
    classForm: String,
    disabled: Boolean
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value)
    }
  },
}
</script>