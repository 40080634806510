import Vue from 'vue'
import VueRouter from 'vue-router'
import publicRoutes from '@/router/public'
import accountRoutes from '@/router/account'
import mobileRoutes from '@/router/mobile'
import errorsRoutes from '@/router/errors'
import store from '@/store'
import Qs from 'qs'
import isMobileDevice from '@/components/utils/mobile/isMobileDevice'
import menuConfigs from '@/config/menuConfigs'
import { BASE_URL } from '@/constants/urls'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: BASE_URL,
  routes: [
    ...publicRoutes,
    ...accountRoutes,
    ...mobileRoutes,
    ...errorsRoutes
  ],
  parseQuery: params => Qs.parse(params),
  stringifyQuery: params => {
    let queryString = ''
    if (Object.keys(params).length) {
      queryString += '?'
    }
    return queryString + Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    })
  }
})

router.beforeEach(async (to, from, next) => {
  const isNeedAuth = to.matched.some(route => route.meta.auth)
  const isLogged = !!store.getters['auth/getJwt']
  const isMobile = isMobileDevice()
  const isPageAuth = ['home', 'mobileHome', 'mobileLogin', 'mobileRecoveryPassword'].includes(to.name)

  const currentRole = store.getters['auth/getCurrentRole']
  const configByName = menuConfigs.find((item) => item.menuItem === to.name)
  const typePathByDevice = to.path.match(/\/(\w+)/) //account or mobile

  if (isMobile && to.path === '/') {
    next('/mobile')
  } else if (isNeedAuth && !isLogged) {
    if (isMobile) {
      router.push({ name: 'loginMobile' }, () => {
      })
    } else {
      router.push({ name: 'login' }, () => {
      })
    }
  } else if (isLogged && isPageAuth) { //выкинуть из страницы авторизации, если уже авторизованы
    isMobile ?
      next('/mobile/map') :
      next('/account/map')
  }
  if (configByName?.role?.length && typePathByDevice && typePathByDevice[0] === '/account') {
    const isAllow = (configByName.role.includes(currentRole))
    if (isAllow) {
      next()
    } else {
      next('/account/map')
      await store.dispatch('systemMessages/error', 'Эта страница вам недоступна')
    }
  } else {
    next()
  }
})

export default router
